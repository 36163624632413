<template>
  <div class="page-403">
    <div class="page-content">
      403,权限不足
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.page-403 .page-content
{
  margin-top: 50px;
  width:100%;
  height:80px;
  line-height: 80px;
  color:#bbb;
  text-align: center;
  font-size: 40px;
}
</style>
