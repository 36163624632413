<template>
  <div class="of-box">
    <div class="of-container is-vertical">
      <div class="of-aside">
        <div class="of-filter clearfix">
          <div class="filter-left">
            <el-button size="small" type="primary" @click="showLic">导入待授权证书</el-button>
          </div>
          <div class="filter-right">
            <div class="filter-group filter-item limit search-box">
              <el-input v-model="pageQueryParams.FastSearchKeyWord" placeholder="搜索关键字" prefix-icon="el-icon-search" :clearable="true" size="small" @input="onSearch"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div ref="tableBox" class="of-main">
        <div class="of-table">
          <el-table v-loading="loading" :data="listData">
            <el-table-column prop="Name" label="公司名"> </el-table-column>
            <el-table-column prop="Manage" label="管理员"> </el-table-column>
            <el-table-column prop="Phone" label="手机"> </el-table-column>
            <el-table-column prop="Email" label="邮箱"> </el-table-column>
            <el-table-column prop="MaxBotNum" label="机器人总数"> </el-table-column>
            <el-table-column prop="CreateTime" label="创建时间">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="250">
              <template slot-scope="scope">
                <el-button class="table-ctrlbtn" type="text" size="small" icon="el-icon-edit-outline" @click="goto(scope.row)">管理</el-button>
                <el-button class="table-ctrlbtn" type="text" size="small" icon="el-icon-download" @click="generate(scope.row)">生成授权证书</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="of-aside">
        <of-pagination :pageIndex="pageQueryParams.pageIndex" :pageSize="pageQueryParams.pageSize" :total="pageQueryParams.totalCount" @change="pageChange"></of-pagination>
      </div>
    </div>

    <el-dialog v-if="lic.visible" :visible="true" :close-on-click-modal="false" append-to-body title="待授权证书" width="45%" @close="lic.visible = false">
      <el-input v-model="lic.value" :rows="15" type="textarea" placeholder="请粘贴待授权证书"></el-input>
      <div class="footer">
        <el-upload ref="uploadControl" :auto-upload="false" :before-upload="beforeUpload" :on-change="uploadChange" :show-file-list="false" :limit="1" style="display: inline-block;" action accept=".lic">
          <el-button size="small" icon="el-icon-upload2">上传待授权文件</el-button>
        </el-upload>
        <el-button v-loading="lic.loading" type="primary" size="small" :disabled="lic.value ==''" style="margin-left:10px" @click="onSubmit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import moment from "moment";
import OfPagination from "@/components/pagination";
export default {
  components: { OfPagination },
  data() {
    return {
      listData: [],
      pageQueryParams: {
        PageIndex: 1,
        PageSize: 15,
        FastSearchKeyWord: "",
        FastSearchFields: 'custom_mobile',
        SortField: "timestamp",
        SortOrder: "desc",
        localization: 1
      },
      total: 0,
      loading: false,
      lic: {
        visible: false,
        loading: false,
        value: ''
      }
    };
  },
  methods: {
    showLic() {
      this.lic.visible = true
      this.lic.value = ''
    },
    //上传限制
    beforeUpload(file) {
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf("."))
      if (fileType != '.lic') {
        this.$message.error('暂不支持该文件格式')
        return false
      }
      if (file.size / 1024 > 64) {
        this.$message.error("文件不能大于64kb")
        return false
      }
    },
    //上传成功读取文件
    uploadChange(file) {
      let self = this
      let reader = new FileReader();
      reader.readAsText(file.raw, 'UTF-8')//读取，转换字符编码
      reader.onload = function (e) {
        self.lic.value = e.target.result
      }
    },
    pageChange(index, size) {
      this.pageQueryParams.pageIndex = index;
      this.pageQueryParams.pageSize = size;
      //   let postData = {
      //     PageIndex: this.pageIndex,
      //     PageSize: this.pageSize,
      //   };
      this.loadList()
    },
    // 搜索
    onSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.pageQueryParams.PageIndex = 1;
        this.loadList();
      }, 800);
    },
    loadList() {
      let params = Object.assign(this.pageQueryParams);
      this.$http.post("/api/tenantBot/query", params).then((res) => {
        if (res.Code == 0) {
          let data = res.Data;
          this.listData = data.Rows;
          this.pageQueryParams.totalCount = data.Total;
        }
      });
    },
    pageSizeChange(size) {
      this.pageQueryParams.PageSize = size;
      this.pageQueryParams.PageIndex = 1;
      this.loadList();
    },
    pageIndexChange(index) {
      this.pageQueryParams.PageIndex = index;
      this.loadList();
    },
    goto(row) {
      this.$router.push('/customer/manager?Id=' + row.Id);
    },
    generate(row) {
      window.open('/api/TenantBot/Generate?id=' + row.Id)
    },
    onSubmit() {
      let params = Object.assign({ lic: this.lic.value });
      this.$http.post("/api/TenantBot/ImportLic", params).then((res) => {
        if (res.Code == 0) {
          this.lic.visible = false
          this.lic.value = ''
          this.loadList()
          this.$message.success('导入成功')
        } else {
          this.$message.error(res.Message)
        }
      })
    }
  },
  mounted() {
    this.loadList();
  }
};
</script>

<style lang="scss" scoped>
/deep/.upload-demo {
  .el-upload {
    .el-upload-dragger {
      width: 100px;
    }
  }
}
.footer {
  margin-top: 20px;
  text-align: right;
}
.oc-container {
  margin-top: 1rem;
  .oc-title {
    margin-bottom: 30px;
    .oc-title-text {
      float: left;
    }
  }
  .opera-box {
    .opera-box-left {
      float: left;
    }
    .opera-box-right {
      float: right;
    }
  }
  .oc-content {
    margin-bottom: 20px;
    .oc-content-table {
      min-height: 350px;
    }
    .oc-content-table-page {
      text-align: right;
    }
  }
}
.oc-drawer {
  .oc-drawer-header {
    padding: 20px;
    .oc-drawer-title {
      font-size: 18px;
    }
  }
  .oc-drawer-content {
    background: #f5f7fa;
    position: absolute;
    bottom: 0;
    top: 65px;
    right: 0;
    left: 0;
    overflow: auto;
    ul li {
      margin: 20px;
    }
    .msg-left {
      .name {
        color: #444;
        max-width: 120px;
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        display: inline-block;
      }
      .content {
        max-width: 400px;
        line-height: 24px;
        padding: 6px 12px;
        background: #fff;
        border-radius: 0 18px 18px 18px;
        border: 1px solid #e0e0e0;
        word-break: break-word;
        display: inline-flex;
      }
      text-align: left;
    }
    .msg-right {
      .name {
        color: #444;
        max-width: 120px;
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        display: inline-block;
      }
      .content {
        max-width: 400px;
        line-height: 24px;
        padding: 6px 12px;
        background: #43c3c6;
        border-radius: 18px 0 18px 18px;
        color: #fff;
        text-align: left;
        word-break: break-word;
        display: inline-flex;
      }
      text-align: right;
    }
  }
}
</style>
