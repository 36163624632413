<template>
  <div class="card-body">
    <div v-show="tenant.localization == 0" class="card-info-item">
      <span>客户填写</span>
      <div class="card-form">
        <div class="form-item">
          <div class="form-label">LOGO</div>
          <div class="form-item-box">
            <FileUpload v-model="employee.tenant_logo" @fileChange="logoChange"></FileUpload>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">公司名：</div>
          <div class="form-item-box">
            <span class="context"><input v-model="employee.tenant_name" class="form-input" type="text" disabled /></span>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">管理员：</div>
          <div class="form-item-box">
            <span class="context"><input v-model="employee.employee_name" class="form-input" type="text" disabled /></span>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">手机号：</div>
          <div class="form-item-box">
            <span class="context"><input v-model="employee.mobile" class="form-input" type="text" disabled /></span>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">邮箱：</div>
          <div class="form-item-box">
            <span class="context"><input v-model="employee.email" class="form-input" type="text" disabled /></span>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">密码：</div>
          <div class="form-item-box">
            <span class="form-read" @click="reset()">重置密码</span>
          </div>
        </div>
      </div>
    </div>
    <div class="card-info-item">
      <span>运营维护</span>
      <div class="card-form">
        <div class="form-item">
          <div class="form-label">LOGO</div>
          <div class="form-item-box">
            <FileUpload v-model="tenant.custom_logo" @fileChange="fileChange"></FileUpload>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">公司名：</div>
          <div class="form-item-box">
            <span class="context"><input ref="custom_company" v-model="tenant.custom_company" class="form-input" :class="{ 'input-active': !disableds.nameDisabled }" type="text" :disabled="disableds.nameDisabled" @blur="onBlur('custom_company', 'nameDisabled')" /></span><span class="form-read" @click="update('custom_company', 'nameDisabled')">修改</span>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">管理员：</div>
          <div class="form-item-box">
            <span class="context"><input ref="custom_admin" v-model="tenant.custom_admin" class="form-input" :class="{ 'input-active': !disableds.adminDisabled }" type="text" :disabled="disableds.adminDisabled" @blur="onBlur('custom_admin', 'adminDisabled')" /></span><span class="form-read" @click="update('custom_admin', 'adminDisabled')">修改</span>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">手机号：</div>
          <div class="form-item-box">
            <span class="context"><input ref="custom_mobile" v-model="tenant.custom_mobile" class="form-input" :class="{ 'input-active': !disableds.mobdileDisabled }" type="text" :disabled="disableds.mobdileDisabled" @blur="onBlur('custom_mobile', 'mobdileDisabled')" /></span><span class="form-read" @click="update('custom_mobile', 'mobdileDisabled')">修改</span>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">邮箱：</div>
          <div class="form-item-box">
            <span class="context"><input ref="custom_email" v-model="tenant.custom_email" class="form-input" :class="{ 'input-active': !disableds.emailDisabled }" type="text" :disabled="disableds.emailDisabled" @blur="onBlur('custom_email', 'emailDisabled')" /></span><span class="form-read" @click="update('custom_email', 'emailDisabled')">修改</span>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">备注：</div>
          <div class="form-item-box">
            <span class="context"><input ref="custom_remark" v-model="tenant.custom_remark" class="form-input" :class="{ 'input-active': !disableds.remarkDisabled }" type="text" :disabled="disableds.remarkDisabled" @blur="onBlur('custom_remark', 'remarkDisabled')" /></span><span class="form-read" @click="update('custom_remark', 'remarkDisabled')">修改</span>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">可创建机器人数：</div>
          <div class="form-item-box">
            <span class="context"><input ref="max_bot" v-model="tenant.max_bot" class="form-input" :class="{ 'input-active': !disableds.maxBotDisabled }" type="text" :disabled="disableds.maxBotDisabled" @blur="onBlur('max_bot', 'maxBotDisabled')" /></span><span class="form-read" @click="update('max_bot', 'maxBotDisabled')">修改</span>
          </div>
        </div>
        <div v-show="tenant.localization === 0" class="form-item">
          <div class="form-label">到期时间：</div>
          <div class="form-item-box">
            <span class="context">
              <el-date-picker ref="tenant_expired_time" v-model="tenant.tenant_expired_time" type="date" :disabled="disableds.expiredTimeDisabled" placeholder="选择日期" @blur="onBlur('tenant_expired_time', 'expiredTimeDisabled')">
              </el-date-picker>
            </span><span class="form-read" @click="update('tenant_expired_time', 'expiredTimeDisabled')">修改</span>
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">客服坐席：</div>
          <div class="form-item-box">
            <span class="context">
              <select ref="has_seat" v-model="tenant.has_seat" :disabled="disableds.hasSeatDisabled" placeholder="禁用" style="padding: 5px 10px;" @blur="onBlur('has_seat', 'hasSeatDisabled')">
                <option label="开启" value="1">
                </option>
                <option label="禁用" value="0">
                </option>
              </select>
            </span><span class="form-read" @click="update('has_seat', 'hasSeatDisabled')">修改</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from "../components/file-upload";
export default {
  components: {
    FileUpload,
  },
  props: {
    Id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pageData: [],
      pageHeight: 550,
      selectRows: [],
      form: {
        Name: "",
        CreateTime: this.$date.format("yyyy-MM-dd hh:mm:ss", new Date()),
        OverdueTime: "",
        capacity: {
          checkbox: [],
          count: 5,
        },
      },
      rules: {},
      imageUrl: "",
      employee: {
        tenant_name: "",
        tenant_logo: {},
        employee_name: "",
        mobile: "",
        email: "",
        password: "",
      },
      tenant: {
        custom_logo: {},
        custom_company: "",
        custom_admin: "",
        custom_mobile: "",
        custom_email: "",
        custom_remark: "",
        max_bot: "",
        has_seat: "",
        localization: 0
      },
      disableds: {
        nameDisabled: true,
        adminDisabled: true,
        mobdileDisabled: true,
        emailDisabled: true,
        remarkDisabled: true,
        maxBotDisabled: true,
        hasSeatDisabled: true,
        expiredTimeDisabled: true
      },
      TemporaryVariable: "",
    };
  },
  watch: {
    Id: {
      handler(val) {
        if (val) {
          this.load(val);
        }
      },
      immediate: true,
    },
  },
  methods: {
    reset() {
      this.$confirm("是否重置为原始密码:(123456)", "提示", {
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          let postData = {
            Id: this.Id,
          };
          console.log(postData.Id);
          this.$http.post("api/tenantBot/reset", postData)
            .then((res) => {
              console.log(res);
              if (res.code == 0) {
                this.$confirm("密码重置成功", "提示", {
                  type: "warning"
                });
              }
            })
        }).catch((err) => {
          console.log(err);
        });
    },
    fileChange(val) {
      let postData = {
        Id: this.Id,
        custom_logo: JSON.stringify(val),
      };
      this.upload(postData);
    },
    logoChange(val) {
      let postData = {
        Id: this.Id,
        tenant_logo: JSON.stringify(val),
      };
      this.upload(postData);
    },
    upload(postData) {
      this.$http.post("/api/tenantBot/saveOne", postData).then((res) => {
        if (res.Code == 0) {
          this.load(this.Id);
        }
      });
    },
    load(Id) {
      this.$http.get("/api/tenantBot/get?Id=" + Id).then((res) => {
        if (res.Code == 0) {
          let data = res.Data;
          console.log(data);
          let tenant = data.tenant;
          let employee = data.employee;
          this.tenant = data.tenant;
          this.tenant.custom_logo = JSON.parse(tenant.custom_logo);
          this.tenant.custom_company = tenant.custom_company ? tenant.custom_company : tenant.tenant_name;
          this.tenant.custom_admin = tenant.custom_admin ? tenant.custom_admin : employee.employee_name;
          this.tenant.custom_mobile = tenant.custom_mobile ? tenant.custom_mobile : employee.mobile;
          this.tenant.custom_email = tenant.custom_email ? tenant.custom_email : employee.email;
          this.tenant.custom_remark = tenant.custom_remark;
          this.tenant.has_seat = tenant.has_seat ? tenant.has_seat + "" : "0"

          this.employee.tenant_name = tenant.tenant_name;
          this.employee.tenant_logo = JSON.parse(tenant.tenant_logo);
          this.employee.employee_name = employee.employee_name;
          this.employee.mobile = employee.mobile;
          this.employee.email = employee.email;
          this.employee.password = employee.password;
          this.format(this.tenant);
          this.format(this.employee);
        }
      });
    },
    format(obj) {
      if (typeof obj !== "object") {
        return;
      }
      for (let key in obj) {
        if (key !== "custom_logo" && key !== "tenant_logo" && key !== "localization") {
          obj[key] = this.isNull(obj[key]);
        }
      }
    },
    isNull(row) {
      row = row == "" || row == null ? "未填写" : row;
      return row;
    },
    isNotNull(row) {
      row = row != "" && row != null && row !== "未填写" ? row : "";
      return row;
    },
    update(col, disName) {
      this.disableds[disName] = false;
      this.TemporaryVariable = this.tenant[col];
      console.log(this.TemporaryVariable);
      this.tenant[col] = this.isNotNull(this.tenant[col]);
      this.$nextTick(() => {
        this.$refs[col].focus();
      });
    },
    onBlur(col, disName) {
      this.disableds[disName] = true;
      if (
        this.tenant[col] !== this.TemporaryVariable &&
        !(this.TemporaryVariable === "未填写" && this.tenant[col] === "")
      ) {
        this.$confirm("内容被修改，是否继续", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(
            () => {
              let postData = {
                Id: this.Id,
              };
              postData[col] = this.tenant[col];
              this.$http
                .post("/api/tenantBot/saveOne", postData)
                .then((res) => {
                  if (res.Code == 0) {
                    this.load(this.Id);
                    this.$message.success("保存成功");
                  }
                });
              console.log("111");
            },
            () => {
              this.tenant[col] = this.TemporaryVariable;
            }
          )
          .then(() => {
            this.TemporaryVariable = "";
          });
      } else {
        this.tenant[col] = this.TemporaryVariable;
        this.TemporaryVariable = "";
      }
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  .btn {
    width: 100px;
  }
  .active {
    background: #409eff;
    color: #fff;
  }
}
.card-body {
  display: flex;
  .card-info-item {
    flex: 1;
    .card-form {
      margin-top: 1rem;
      width: 90%;
      height: 550px;
      background: #e9e9e9;
    }
  }
}
.form-item {
  display: flex;
  padding: 10px;
}
.form-label {
  width: 150px;
  line-height: 30px;
}
.form-item-box {
  padding-left: 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.form-read {
  color: #409eff;
  cursor: pointer;
  padding-right: 10%;
}
.context {
  margin-right: 1rem;
}
.form-input {
  border: 1px solid #e9e9e9;
  background: #e9e9e9;
  padding: 5px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 170px;
  height: 100px;
  line-height: 100px;
  background: #fff;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.input-active {
  border: 1px solid #000;
}
</style>