function getFile(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        let fileJson = {}
        reader.onload = () => {
            let base64Str = reader.result;
            let type;
            let arr = file.name.split(".");
            type = arr[arr.length - 1];
            fileJson["FileName"] = file.name
            fileJson["FileData"] = base64Str
            fileJson["FileSize"] = file.size
            fileJson["FileType"] = type
        };
        reader.onerror = function (error) {
            reject(error);
        };
        reader.onloadend = function () {
            resolve(fileJson);
        };
    })
}

export default {
    getFile: function (file, fileJson) {
        return getFile(file, fileJson)
    },
}