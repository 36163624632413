<template>
  <div class="card-body">
    <div>
      可建机器人总数:
      <span class="blue">{{ maxBot }}</span>， 已建： <span class="blue">{{ createBot }}</span>， 还可再建设：
      <span class="danger">{{ maxBot - createBot }}</span>
    </div>
    <div class="bot-box">
      <el-card v-for="(item, index) in botList"
               :key="index"
               class="bot-item">
        <div class="bot-title">{{ item.bot_name }}</div>
        <div class="bot-info">
          <div class="bot-info-item">
            <div class="bot-info-item-label">创建时间</div>
            <div class="bot-info-item-data">{{ item.created_time }}</div>
          </div>
          <div class="bot-info-item">
            <div class="bot-info-item-label">过期时间</div>
            <div class="bot-info-item-data">{{ item.expired_time }}</div>
          </div>
          <div class="bot-info-item tag-box">
            <div class="bot-info-item-label">状态</div>
            <div class="bot-info-item-data tag-item">
              <div class="tag"
                   :class="{
                  'tag-success': item.status == 1,
                  'tag-warning': item.status == 2,
                  'tag-danger': item.status == 3,
                }">
                {{ item.statusName }}
              </div>
            </div>
          </div>
          <div class="bot-info-item btn-box">
            <el-button class="btn"
                       size="small"
                       @click="manage(item)">管理</el-button>
          </div>
        </div>
      </el-card>
    </div>
    <of-drawer :visible="showPipDrawer"
               :title="drawerTitle"
               :isSubmit="isSubmit"
               :submitName="submitName"
               @onCancel="onCancel"
               @onSubmit="onSubmit">
      <of-form-project slot="content"
                       ref="form"
                       :formData="formData"></of-form-project>
    </of-drawer>
  </div>
</template>

<script>
import ofDrawer from "@/components/drawer";
import ofFormProject from "./drawerForm/addForm";
export default {
  components: {
    ofDrawer,
    ofFormProject,
  },
  props: {
    Id: {
      type: String,
      default: "",
    },
  },
  data () {
    return {
      botList: [],
      showPipDrawer: false,
      dialogVisible: false,
      isSubmit: false,
      formData: {},
      drawerTitle: "",
      submitName: "保存",
      maxBot: 0,
      createBot: 0,
    };
  },
  watch: {
    Id: {
      handler (val) {
        if (val) {
          console.log(val);
          this.load(val);
        }
      },
      immediate: true,
    },
  },
  methods: {
    manage (row) {
      console.log("row", row);
      this.drawerTitle = "机器人管理";
      this.formData = row;
      this.showPipDrawer = true;
    },
    load (Id) {
      this.$http.get("/api/tenantBot/getBot?Id=" + Id).then((res) => {
        if (res.Code == 0) {
          console.log(res);
          let data = res.Data.bots;
          let list = [];
          data.forEach((e) => {
            if (e.expired_time == null) {
              e.expired_time = "1970-01-01 00:00:00"
            }
            let endTime = e.expired_time.split(" ")[0];
            let now = this.$date.format("yyyy-MM-dd", new Date());
            let day = this.$date.getDays(endTime, now);
            let status = 0;
            let statusName = "";
            if (day >= 15) {
              status = 1;
              statusName = "正常";
            } else if (0 <= day && day <= 15) {
              status = 2;
              statusName = "即将过期";
            } else {
              status = 3;
              statusName = "过期";
            }
            list.push({
              Id: e.Id,
              tid: e.tid,
              bot_name: e.bot_name,
              created_time: e.created_time.split(" ")[0],
              expired_time: e.expired_time.split(" ")[0],
              bot_ability: e.bot_ability,
              status: status,
              statusName: statusName,
              faq_num: e.faq_num,
              change_faq_num_reason: e.change_faq_num_reason
            });
          });
          this.botList = list;
          this.maxBot = res.Data.maxBot;
          this.createBot = list.length;
        }
      });
    },
    onSubmit () {
      let _this = this;
      this.isSubmit = true;
      this.$refs.form.submit((valid, data) => {
        _this.isSubmit = false;
        if (valid) {
          _this.showPipDrawer = false;
          this.$message.success(data);
          _this.$refs.form.resetFields(); // 重置表单方法
          _this.formData = {};
          _this.load(_this.Id);
        } else {
          _this.$message.error(
            `表单验证失败，提交内容：${JSON.stringify(data)}`
          );
        }
      });
    },
    onCancel () {
      this.showPipDrawer = false;
      this.formData = {};
    },
  },
};
</script>

<style  scoped>
.bot-box {
  display: flex;
  flex-wrap: wrap;
}
.bot-item {
  margin-top: 1rem;
  margin-right: 3%;
  height: 280px;
  width: 30%;
}
.bot-title {
  font-weight: bold;
  font-size: 15px;
}
.bot-info {
  margin-top: 5px;
  font-size: 13px;
  line-height: 17px;
}
.bot-info-item {
  height: 37px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}
.bot-info-item-label {
  width: 70px;
}
.tag {
  text-align: center;
  font-size: 10px;
  line-height: 25px;
  width: 60px;
  height: 25px;
  border-radius: 0.25rem;
  color: #fff;
}
.tag-success {
  background: #43c3c6;
}
.tag-warning {
  background: #ceb534;
}
.tag-danger {
  background: #ff0500;
}
.tag-box .tag-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-box {
  margin-top: 3rem;
  justify-content: center;
  height: 50px;
}
.btn-box /deep/ .el-button {
  width: 150px;
  background: #298df8;
  color: #fff;
}
.blue {
  color: #298df8;
}
.danger {
  color: #f56c6c;
}
</style>