<template>
  <div class="oc-container-ms">
    <div class="oc-title oc-title-menu">
      <el-card shadow="aways">
        <div class="clear-both">
          <div class="oc-title-text">异常日志</div>
        </div>
      </el-card>
    </div>
    <!-- title -->
    <div class="oc-container-fix">
      <div class="oc-container">
        <div class="opera-box">
          <div class="clear-both">
            <div class="opera-box-left">
            </div>
            <div class="opera-box-right">
              <el-input v-model="pageQueryParams.FastSearchKeyWord"
                        placeholder="搜索关键字"
                        prefix-icon="el-icon-search"
                        :clearable="true"
                        @input="onSearch"></el-input>
            </div>
          </div>
        </div>
        <div class="oc-content">
          <el-card shadow="aways">
            <div class="oc-content-table">
              <el-table v-loading="loading"
                        :data="listData">
                <el-table-column prop="name_space"
                                 label="命名空间">
                </el-table-column>
                <el-table-column prop="server_name"
                                 label="服务名称">
                </el-table-column>
                <el-table-column prop="server_type"
                                 label="服务类型">
                </el-table-column>
                <el-table-column prop="pod_name"
                                 label="实例名称">
                </el-table-column>
                <el-table-column prop="env"
                                 label="运行参数">
                </el-table-column>
                <el-table-column prop="message"
                                 label="错误信息">
                </el-table-column>
                <el-table-column prop="data"
                                 label="自定义数据">
                </el-table-column>
                <el-table-column prop="stack"
                                 label="错误堆栈">
                </el-table-column>
                <el-table-column prop="timestamp"
                                 label="时间戳">
                  <template slot-scope="scope">
                    <span>{{ getTime(scope.row.timestamp) }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="oc-content-table-page">
              <pagination :total="total"
                          :pageSize="pageQueryParams.PageSize"
                          :currentPage="pageQueryParams.PageIndex"
                          @onSizeChange="pageSizeChange"
                          @onCurrentChange="pageIndexChange" />
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Pagination from '@/components/pagination'
export default {
  name: 'OcException',
  components: { Pagination },
  data () {
    return {
      listData: [],
      pageQueryParams: {
        PageIndex: 1,
        PageSize: 15,
        FastSearchKeyWord: '',
        SortField: 'timestamp',
        SortOrder: 'desc'
      },
      total: 0,
      loading: false
    }
  },
  mounted () {
    this.loadList()
  },
  methods: {
    // 搜索
    onSearch () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.pageQueryParams.PageIndex = 1
        this.loadList()
      }, 800)
    },
    loadList () {
      this.loading = true
      let params = Object.assign(this.pageQueryParams)
      this.$http.get('/api/Monitor/QueryException', {
        params: params
      }).then(res => {
        this.loading = false
        if (res && res.code === 0) {
          this.listData = res.result.rows
          this.total = res.result.total
        } else {
          this.$message.error(res ? res.msg : '请求异常')
        }
      })
    },
    pageSizeChange (size) {
      this.pageQueryParams.PageSize = size
      this.pageQueryParams.PageIndex = 1
      this.loadList()
    },
    pageIndexChange (index) {
      this.pageQueryParams.PageIndex = index
      this.loadList()
    },
    getTime (item) {
      return moment(Number(item)).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style lang="scss" scoped>
.oc-container {
  padding: 20px;
  .oc-title {
    margin-bottom: 30px;
    .oc-title-text {
      float: left;
    }
  }
  .opera-box {
    .opera-box-left {
      float: left;
    }
    .opera-box-right {
      float: right;
    }
  }
  .oc-content {
    margin-bottom: 20px;
    .oc-content-table {
      min-height: 350px;
    }
    .oc-content-table-page {
      text-align: right;
    }
  }
}
.oc-drawer {
  .oc-drawer-header {
    padding: 20px;
    .oc-drawer-title {
      font-size: 18px;
    }
  }
  .oc-drawer-content {
    background: #f5f7fa;
    position: absolute;
    bottom: 0;
    top: 65px;
    right: 0;
    left: 0;
    overflow: auto;
    ul li {
      margin: 20px;
    }
    .msg-left {
      .name {
        color: #444;
        max-width: 120px;
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        display: inline-block;
      }
      .content {
        max-width: 400px;
        line-height: 24px;
        padding: 6px 12px;
        background: #fff;
        border-radius: 0 18px 18px 18px;
        border: 1px solid #e0e0e0;
        word-break: break-word;
        display: inline-flex;
      }
      text-align: left;
    }
    .msg-right {
      .name {
        color: #444;
        max-width: 120px;
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        display: inline-block;
      }
      .content {
        max-width: 400px;
        line-height: 24px;
        padding: 6px 12px;
        background: #43c3c6;
        border-radius: 18px 0 18px 18px;
        color: #fff;
        text-align: left;
        word-break: break-word;
        display: inline-flex;
      }
      text-align: right;
    }
  }
}
</style>
