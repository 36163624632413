<template>
  <div>
    <el-upload
      class="avatar-uploader"
      :show-file-list="false"
      action
      :http-request="uploadFile"
      :multiple="multiple"
      :disabled="disabled"
      :before-upload="beforeUpload"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileList: {},
      imageUrl: "",
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val != "" && val != null) {
          this.fileList = val;
          this.imageUrl = val.url;
        }
      },
      deep: true,
    },
  },
  methods: {
    beforeUpload(file) {
      if (!/^image/.test(file.type)) {
        this.$message.error("请上传图片格式文件！");
        return false;
      }
      const isLt2M = file.size / 1024 < 100;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 100kb!");
        return false;
      }
      return true;
    },
    uploadFile(data) {
      console.log(data);
      let fileJson = {};
      this.$util
        .getFile(data.file)
        .then((data) => {
          fileJson = data;
        })
        .then(() => {
          this.$http.post("/api/sysFile/saveOne", fileJson).then((res) => {
            if (res && res.Code === 0) {
              // 响应处理
              this.$message.success("上传成功");
              let file = res.Data;
              this.fileList = {
                Id: file.Id,
                name: file.FileName,
                url: file.FileData,
              };
              this.imageUrl = file.FileData;
              this.$emit("fileChange", this.fileList);
            } else {
              this.$message.error(
                res ? res.Message : "服务器请求超时，请稍后再试。"
              );
            }
          });
        });
    },
    // download(file) {
    //   console.log(file);
    //   if (file.Id) {
    //     this.$http.get(`/api/sysfile/get?Id=${file.Id}`).then((res) => {
    //       if (res.Code == 0) {
    //         let data = res.Data;
    //         this.$tools.download(data.FileData, data.FileName);
    //       }
    //     });
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 170px;
  height: 100px;
  line-height: 100px;
  background: #fff;
  text-align: center;
}
.avatar {
  width: 170px;
  height: 100px;
  display: block;
}
.input-active {
  border: 1px solid #000;
}
</style>