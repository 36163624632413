<template>
  <div class="of-container clear-both">
    <div class="of-logo" @click="goto('/')">
      <img src="@/assets/images/base/logo.png" />
    </div>
    <template v-if="currentUserInfo && IsLogin">
      <!-- logo -->
      <div v-show="IsLogin" class="of-user">
        <el-dropdown @command="handleCommand">
          <div class="el-dropdown-link">
            <font>
              <img :src="currentUserInfo.avatar || userDefault.avatar" />
            </font>
            <i class="el-icon-arrow-down"></i>
            <span>
              {{ currentUserInfo.userName || userDefault.name }}
            </span>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in userMenu" :key="index" :command="item.url">
              {{ item.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <!-- user -->
      <!-- <div class="of-info">
        <i class="far fa-bell"></i>
        <font v-if="messageCount && messageCount > 0">
          {{ messageCount > 99 ? '99+' : messageCount }}
        </font>
      </div> -->
    </template>
  </div>
</template>

<script>
export default {
  name: 'OfHeader',
  data() {
    return {
      userDefault: {
        name: '管理员',
        // eslint-disable-next-line no-undef
        avatar: require('@/assets/images/base/header-default.jpg')
      },
      userMenu: [
        { name: '退出登录', url: 'logout' }
      ],
      currentUserInfo: {}
    }
  },
  computed: {
    IsLogin() {
      return true
    }
  },
  methods: {
    handleCommand(command) {
      if (command == "logout") {
        console.log("logout => 登出操作")
        // this.goto(command)
      }
    },
    goto(path) {
      if (path) this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
