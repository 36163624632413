<template>
  <div class="oc-container">
    <div class="login-content">
      <div v-for="(item, index) in loginHeader" :key="index" class="login-header">
        <ul v-if="setLoginHeader(index)" class="clear-both">
          <li v-for="(titem, tindex) in item" :key="tindex">
            <font :class="{'active': titem.id === loginActive}" @click="loginChange(titem.id)">
              <span>{{ titem.name }}</span>
            </font>
          </li>
        </ul>
      </div>
      <!-- 密码登录 -->
      <div v-show="loginActive === '2'" class="login-item">
        <el-form ref="accountForm" :model="ruleForm" :rules="rules" class="login-form">
          <el-form-item :class="setFormClass('account')" class="login-form-group" prop="account">
            <el-input v-model="ruleForm.account" @focus="changeFocus('account')" @blur="changeFocus()"></el-input>
            <div class="form-placeholder">请输入账号</div>
          </el-form-item>
          <!-- account -->
          <el-form-item :class="setFormClass('password')" class="login-form-group" prop="password">
            <el-input v-model="ruleForm.password" show-password @focus="changeFocus('password')" @blur="changeFocus()">
            </el-input>
            <div class="form-placeholder">请输入密码</div>
          </el-form-item>
          <!-- password -->
          <!-- group -->
          <el-button type="primary login-form-btn" @click="LoginByAccount()">登 录</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OcLoginPanel',
  components: {},
  data() {
    var checkAccount = (rule, value, callback) => {
      value = this.trimLine(value)
      if (value === '' || value.length < 3 || value.length > 50) {
        callback(new Error('请输入长度为3到50个字符的账号'))
      } else {
        callback()
      }
    }
    var checkPassword = (rule, value, callback) => {
      value = this.trimLine(value)
      if (value === '' || value.length < 6 || value.length > 12) {
        callback(new Error('请输入长度为6到12个字符的密码'))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        account: '',
        is_longtime: false,
        password: ''
      },
      rules: {
        account: [
          { validator: checkAccount, trigger: ['blur', 'change'], required: true }
        ],
        password: [
          { validator: checkPassword, trigger: ['blur', 'change'], required: true }
        ]
      },
      formFocus: '',
      loginHeader: [
        [
          { name: '账号密码登录', id: '2' }
        ]
      ],
      loginActive: '2'
    }
  },
  computed: {

  },
  watch: {
    loginActive() {
      this.ruleForm = {
        account: '',
        is_longtime: false,
        password: ''
      }
      this.$refs.accountForm.clearValidate()
    }
  },
  mounted() {
  },
  methods: {
    trimLine: function (str) {
      if (str) {
        str = str.replace(/(^\s*)|(\s*$)/g, '')
        if (str) {
          str = str.replace(/[\r\n]/g, '')
        }
      }
      return str
    },
    querySearch(queryString, cb) {
      var loginAccount = this.loginAccount || [];
      var results = queryString ? loginAccount.filter(this.createFilter(queryString)) : loginAccount;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (loginAccount) => {
        return (loginAccount.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    // 选项改变
    loginChange(id) {
      if (id) this.loginActive = id
    },
    // 设置标题
    setLoginHeader(index) {
      let isShow = false
      this.loginHeader[index].forEach((tItem) => {
        if (tItem.id === this.loginActive) {
          isShow = true
        }
      })
      return isShow
    },
    // 设置表单样式
    setFormClass(form) {
      let formClass = {}
      if (form && this.formFocus === form) {
        formClass['form-focus'] = true
      }
      if (form && this.ruleForm[form] && this.ruleForm[form].length > 0) {
        formClass['form-assigned'] = true
      }
      return formClass
    },
    // 焦点更改
    changeFocus(form) {
      let focusType = ''
      if (form) {
        focusType = form
      }
      this.formFocus = focusType
    },
    // 账号登录
    LoginByAccount() {
      var _this = this
      this.$refs.accountForm.validate(val => {
        if (val) {
          var request = {
            'account': this.trimLine(_this.ruleForm.account),
            'password': this.trimLine(_this.ruleForm.password),
            'is_longtime': _this.ruleForm.is_longtime
          }
          this.$http.get('/api/Auth/Login?account=' + request.account + '&password=' + request.password).then(res => {
            if (res && res.Code == 0) {
              _this.$router.push('/')
            } else {
              _this.$message.error(res ? res.Message : '登录错误，请稍后重试')
            }
          })
        } else {
          _this.$message.error('请输入正确的账号和密码')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.oc-container {
  width: 100%;
  height: 100%;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  .login-content {
    width: 20%;
    height: 530px;
    .login-header {
      ul {
        margin-bottom: 40px;
        li {
          float: left;
          font {
            position: relative;
            display: block;
            padding-left: 50px;
            &.active {
              span {
                font-weight: bold;
                color: $--oc-color-theme;
                &::after {
                  left: 0;
                  width: 100%;
                  height: 3px;
                }
              }
            }
            &::before {
              position: absolute;
              left: 25px;
              top: 50%;
              display: block;
              content: '';
              width: 4px;
              height: 4px;
              margin-top: -2px;
              margin-left: -2px;
              border-radius: 100%;
              background: $--oc-color-text-regular;
            }
            span {
              position: relative;
              display: block;
              font-size: 20px;
              line-height: 40px;
              white-space: nowrap;
              color: $--oc-color-text-regular;
              transition: $--oc-all-transition;
              -moz-transition: $--oc-all-transition;
              -o-transition: $--oc-all-transition;
              -webkit-transition: $--oc-all-transition;
              cursor: pointer;
              &:hover {
                color: $--oc-color-theme;
              }
              &::after {
                position: absolute;
                left: 50%;
                bottom: 0;
                display: block;
                content: '';
                width: 0;
                height: 0;
                background: $--oc-color-theme;
                transition: $--oc-all-transition;
                -moz-transition: $--oc-all-transition;
                -o-transition: $--oc-all-transition;
                -webkit-transition: $--oc-all-transition;
              }
            }
          }
          &:first-child {
            font {
              padding: 0;
              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
    .login-item {
      $-oc-item-margin: 40px;
      .login-form-group {
        position: relative;
        padding-bottom: 20px;
        margin-bottom: -20px;
        margin-top: $-oc-item-margin;
        overflow: hidden;
        &:first-child {
          margin-top: 0;
        }
        &::before {
          position: absolute;
          left: 0;
          bottom: 20px;
          display: block;
          width: 0;
          height: 1px;
          content: '';
          background: $--oc-color-theme;
          transition: $--oc-all-transition;
          -moz-transition: $--oc-all-transition;
          -o-transition: $--oc-all-transition;
          -webkit-transition: $--oc-all-transition;
          z-index: 2;
        }
        &::after {
          position: absolute;
          left: 0;
          bottom: 20px;
          display: block;
          width: 100%;
          height: 1px;
          content: '';
          background: $--oc-border-color-base;
          transition: $--oc-all-transition;
          -moz-transition: $--oc-all-transition;
          -o-transition: $--oc-all-transition;
          -webkit-transition: $--oc-all-transition;
          z-index: 1;
        }
        &:hover {
          &::after {
            background: $--oc-color-text-placeholder;
          }
        }
        &.form-focus {
          &::before {
            width: 100%;
          }
          .form-placeholder {
            transform: translate(-100%, 0);
            -webkit-transform: translate(-100%, 0);
          }
        }
        &.form-assigned {
          .form-placeholder {
            transform: translate(-100%, 0);
            -webkit-transform: translate(-100%, 0);
          }
        }
        &.is-error {
          &::after {
            background: $--oc-color-danger;
          }
          .form-placeholder {
            color: $--oc-color-danger;
          }
        }
        .form-placeholder {
          position: absolute;
          top: 0;
          left: 0;
          display: inline-block;
          font-size: 18px;
          line-height: 40px;
          height: 40px;
          color: $--oc-color-text-placeholder;
          transition: $--oc-all-transition;
          -moz-transition: $--oc-all-transition;
          -o-transition: $--oc-all-transition;
          -webkit-transition: $--oc-all-transition;
          z-index: 1;
        }
        .el-input {
          display: block;
          z-index: 2;
          .inline-btn {
            display: block;
            font-weight: bold;
            line-height: 40px;
            font-size: 16px;
            color: $--oc-color-text-secondary;
            transition: $--oc-all-transition;
            -moz-transition: $--oc-all-transition;
            -o-transition: $--oc-all-transition;
            -webkit-transition: $--oc-all-transition;
            cursor: pointer;
            &:hover {
              color: $--oc-color-theme;
            }
          }
        }
        .el-input-group {
          display: table;
        }
      }
      .login-item-group {
        padding: 30px 0;
        margin: 0;
        .item-info {
          font-size: 15px;
          line-height: 20px;
          .el-checkbox {
            color: $--oc-color-text-secondary;
          }
          .item-btn {
            display: block;
            color: $--oc-color-text-secondary;
            transition: $--oc-all-transition;
            -moz-transition: $--oc-all-transition;
            -o-transition: $--oc-all-transition;
            -webkit-transition: $--oc-all-transition;
            cursor: pointer;
            &:hover {
              color: $--oc-color-theme;
            }
          }
          .item-link {
            display: block;
            color: $--oc-color-theme;
            transition: $--oc-all-transition;
            -moz-transition: $--oc-all-transition;
            -o-transition: $--oc-all-transition;
            -webkit-transition: $--oc-all-transition;
            cursor: pointer;
          }
          & > p {
            .item-link {
              display: inline;
            }
          }
          &.t-layout {
            .t-layout-l {
              span {
                float: left;
              }
            }
            .t-layout-s {
              span {
                float: right;
              }
            }
          }
        }
        .t-layout {
          .t-layout-l {
            .el-checkbox-group {
              overflow: hidden;
              .el-checkbox {
                float: left;
              }
            }
          }
        }
      }
      .login-form-btn {
        display: block;
        width: 100%;
        font-size: 18px;
        line-height: 20px;
        padding: 13px 20px;
        margin-top: 20px;
        &.has-margin {
          margin-top: $-oc-item-margin;
        }
      }
      .login-item-other {
        margin-top: 35px;
        dt {
          position: relative;
          text-align: center;
          line-height: 20px;
          height: 20px;
          &::before {
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            content: '';
            background: $--oc-border-color-light;
          }
          span {
            position: relative;
            display: inline-block;
            padding: 0 20px;
            font-size: 14px;
            color: $--oc-color-text-placeholder;
            background: $--oc-color-white;
            z-index: 2;
          }
        }
        dd {
          text-align: center;
          padding: 10px;
          a {
            display: inline-block;
            padding: 10px;
            cursor: pointer;
            i {
              font-size: 24px;
              &.fa-weixin {
                color: #00bb30;
              }
            }
          }
        }
      }
    }
  }
}
.no-margin {
  margin: 0;
}
</style>
