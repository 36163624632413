<template>
  <div class="oc-msg-publish">
    <div class="of-box">

      <!-- 操作栏 -->
      <div class="of-container is-vertical">
        <div class="of-aside">

          <!-- 添加公告 -->
          <div class="of-filter clearfix">
            <div class="filter-left">
              <el-button size="small"
                         type="primary"
                         @click="showNotice = true">发布公告</el-button>
            </div>
          </div>

          <!-- 搜索栏 -->
          <div class="of-filter clearfix">
            <div class="filter-left">
              <el-select v-model="queryMsgType"
                         size="small"
                         style="width:100px">
                <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>

            <div class="filter-left" style="margin-left:10px">
              <el-date-picker
                v-model="queryTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="daterange"
                align="right"
                size="small"
                unlink-panels
                range-separator="——"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
              </el-date-picker>
            </div>

            <div class="filter-left" style="margin-left:10px">
              <div class="filter-group filter-item limit search-box">
                <el-input v-model="pageQueryParams.FastSearchKeyWord"
                          placeholder="搜索关键字"
                          prefix-icon="el-icon-search"
                          :clearable="true"
                          size="small"
                          @input="loadList()"></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 数据表格 -->
      <div ref="tableBox"
           class="of-main">
        <div class="of-table">
          <el-table v-loading="loading"
                    :data="dataList">
            <el-table-column prop="title"
                             label="公告名称"></el-table-column>
            <el-table-column prop="type"
                             label="公告类型"></el-table-column>
            <el-table-column prop="create_person"
                             label="创建账户"></el-table-column>
            <el-table-column prop="read"
                             label="已读人数"></el-table-column>
            <el-table-column prop="unread"
                             label="未读人数"></el-table-column>
            <el-table-column prop="created_time"
                             label="创建时间"></el-table-column>
            <el-table-column prop="operate"
                             label="操作">
              <template slot-scope="scope">
                <el-button type="text"
                           @click="show(scope.row)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 分页组件 -->
        <div class="of-aside">
          <of-pagination :pageIndex="pageQueryParams.pageIndex"
                         :pageSize="pageQueryParams.pageSize"
                         :total="total"
                         @change="pageChange"></of-pagination>
        </div>

      </div>

      <!-- 发布公告 弹窗 -->
      <el-dialog title="发布公告" 
                 :visible.sync="showNotice"
                 append-to-body
                 :close-on-click-modal="false">
        <el-form ref="form"
                 :model="formData"
                 :rules="rules"
                 size="small">

          <el-form-item label="公告类型"
                        prop="type">
            <el-select v-model="formData.type"
                       placeholder="请选择公告类型"
                       size="small"
                       class="select"
                       style="width:200px">
              <el-option v-for="(item,index) in publicType"
                         :key="index"
                         :label="item"
                         :value="item"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="公告名称"
                        prop="title">
            <el-input v-model="formData.title"
                      placeholder="请输入公告名称"></el-input>
          </el-form-item>

          <el-form-item label="公告详情"
                        prop="content">
            <el-input v-model="formData.content" 
                      type="textarea"
                      :rows="7"
                      placeholder="请输入公告详情内容"></el-input>
          </el-form-item>

          <el-form-item style="text-align:center">
            <el-button @click="showNotice = false">取消</el-button>
            <el-button type="primary" :disabled="submitBtn" @click="onSubmit">发布</el-button>
          </el-form-item>

        </el-form>
      </el-dialog>


      <!-- 查看详情 弹窗 -->
      <el-dialog title="公告详情" 
                 :visible.sync="showDetail"
                 :close-on-click-modal="false"
                 append-to-body
                 class="detailDialog">
        <el-divider></el-divider>
        <el-row>
          <el-col style="text-align:center;margin-bottom:15px"><div class="grid-content" style="font-size:large;font-weight:1000">{{ showData.title }}</div></el-col>
          <el-col style="text-align:center;margin-bottom:25px"><div class="grid-content" style="font-size:small">{{ showData.created_time }}</div></el-col>
          <el-col :span="20" :offset="2">
            <div class="grid-content cmm-wrapper" 
                 style="width:100%;word-wrap:break-word;text-indent"
                 v-html="showData.content"></div></el-col>
        </el-row>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import OfPagination from "@/components/pagination";

export default {
  name: "Public",
  components: {
    OfPagination
  },
  data() {
    return {
      dataList: [], // 列表数据
      loading: false,
      showNotice: false, // 显示新增窗口
      showDetail: false, // 显示查看详情窗口
      submitBtn: false, // 提交按钮
      queryTime: "", // 查询时间段
      queryMsgType: "全部", // 查询消息类型
      total: 0, // 数据总量
      showData: { // 详情数据
        title: "",
        created_time: "",
        content: ""
      },
      formData: { // 添加表单数据
        title: "",
        type: "",
        content: "",
        create_person: "",
      },
      pageQueryParams: { // 查询参数
        PageIndex: 1,
        PageSize: 15,
        FastSearchKeyWord: "",
        SortField: "created_time",
        SortOrder: "desc",
        StartTime: "",
        EndTime: "",
        MsgType: "全部",
      },
      publicType: ["其他公告", "产品公告", "活动公告"],
      typeOptions: [
        {
          value: '全部',
          label: '全部'
        },
        {
          value: '其他公告',
          label: '其他公告'
        },
        {
          value: '产品公告',
          label: '产品公告'
        },
        {
          value: '活动公告',
          label: '活动公告'
        },
      ],
      rules: {
        type: [
          { required: true, message: "请选择公告类型", trigger: ["change"] }
        ],
        title: [
          { required: true, message: "请填写公告标题", trigger: "blur" },
          { max: 255, message: "最大长度不超过255", trigger: "blur" }
        ],
        content: [
          { required: true, message: "请填写公告内容", trigger: "blur" }
        ]
      },
      pickerOptions: { // 自动填充时间
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  watch: {
    queryTime(val) {
      if (val) {
        this.pageQueryParams.StartTime = val[0];
        this.pageQueryParams.EndTime = val[1];
      }
      this.loadList();
    },
    queryMsgType(val) {
      this.pageQueryParams.MsgType = val;
      this.loadList();
    }
    
  },
  mounted() {
    this.loadList();
  },
  methods: {
    loadList() {
      this.loading = true;
      this.$http.post('/api/message/queryMessagePublic', this.pageQueryParams).then(res => {
        this.loading = false;
        if (res && res.Code == 0) {
          // var reg = new RegExp("<br/>","g");
          var data = res.Data.rows;
          // for (var i = 0; i < data.length; i++) {
          //   data[i]["content"] = data[i]["content"].replace(/<br\/>/g,'\n');
          // }
          this.dataList = data;
          this.total = res.Data.total
        } else {
          this.$message.error(res && res.Message ? res.Message : "获取数据出错！")
        }
      })
    },
    pageChange (index, size) {
      this.pageQueryParams.pageIndex = index;
      this.pageQueryParams.pageSize = size;
      this.loadList()
    },
    show(data) {
      this.showData.title = data.title;
      this.showData.content = data.content;
      console.log(this.showData.content);
      this.showData.created_time = data.created_time;
      this.showDetail = true;
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submitBtn = true;
          this.loading = true;
          this.formData.content = this.formData.content.replace(/\n/gi, "<br/>");
          console.log(this.formData.content);
          this.$http.post('/api/message/createMessagePublic', this.formData).then((res) => {
            this.loading = false;
            if (res && res.Code == 0) {
              this.$message.success("创建成功");
            } else {
              this.$message.error(res && res.Message ? res.Message : "创建出错！");
            }
            this.showNotice = false;
            this.submitBtn = false;
            this.formData = {};
            this.loadList();
          })
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.cmm-wrapper {
  white-space: pre-wrap;
}
.detailDialog /deep/ .el-dialog__body{
  padding-top: 0;
}
.select {
  display: inline-block;
}
</style>