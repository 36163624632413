<template>
  <div class="oc-container-ms">
    <div class="oc-title oc-title-menu">
      <el-card shadow="aways">
        <div class="clear-both">
          <div class="oc-title-text">
            <span class="back" @click="goback">客户信息</span>
          </div>
        </div>
      </el-card>
    </div>
    <!-- title -->
    <div>
      <div class="oc-container">
        <div class="oc-content">
          <el-card class="card" shadow="aways">
            <div slot="header" class="clearfix">
              <el-button class="btn" :class="{ active: tab == 1 }" @click="tabChange(1)">基础信息</el-button>
              <el-button class="btn" :class="{ active: tab == 2 }" @click="tabChange(2)">机器人</el-button>
              <el-button class="btn" :class="{ active: tab == 3 }" @click="tabChange(3)">订阅记录</el-button>
            </div>
            <component :is="tabsActive" :Id="Id"></component>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import Pagination from "@/components/pagination";
import baseInfo from "./baseInfo";
import botList from "./botList";
import subList from "./subList";

export default {
  components: { Pagination, baseInfo, botList, subList },
  data() {
    return {
      tab: 1,
      tabsActive: "baseInfo",
      Id: "",
    };
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    tabChange(index) {
      this.tab = index;
      if (index == 1) {
        this.tabsActive = "baseInfo";
      } else if (index == 2) {
        this.tabsActive = "botList";
      } else if (index == 3) {
        this.tabsActive = "subList";
      }
      console.log(this.tabsActive);
    },
  },
  mounted() {
    this.Id = this.$route.query.Id;
    console.log(this.Id);
  },
};
</script>

<style lang="scss" scoped>
.oc-container {
  min-height: 500px;
  margin-top: 1rem;
  .oc-title {
    margin-bottom: 30px;
    .oc-title-text {
      float: left;
    }
  }
  .opera-box {
    .opera-box-left {
      float: left;
    }
    .opera-box-right {
      float: right;
    }
  }
  .oc-content {
    margin-bottom: 20px;
  }
}
.card {
  .btn {
    width: 100px;
  }
  .active {
    background: #409eff;
    color: #fff;
  }
}
.back {
  color: #409eff;
  cursor: pointer;
}
</style>
