<template>
  <div class="oc-container">
    <div class="oc-title oc-title-menu">
      <el-card shadow="aways">
        <div class="clear-both">
          <div class="oc-title-text">用户统计</div>
        </div>
      </el-card>
    </div>
    <!-- title -->
    <div class="oc-box">
      <el-card shadow="aways">
        <div class="oc-box-title clear-both">
          <div class="name">数据概述</div>
          <!-- <div class="info">
            今天是<span>{{dateNow}}</span>，
            是<span>{{robotName}}</span>加入AladdinGO平台第<span>{{creationTimes}}</span>天，
            累积服务<font>{{servicedNum}}</font>人次
          </div> -->
        </div>
        <!-- title -->
        <div class="oc-box-tools clear-both">
          <el-radio-group v-model="datePickerA.shortcut"
                          @change="loadData">
            <el-radio-button label="今天"></el-radio-button>
            <el-radio-button label="昨天"></el-radio-button>
            <el-radio-button label="前天"></el-radio-button>
            <el-radio-button label="近7天"></el-radio-button>
            <el-radio-button label="近14天"></el-radio-button>
            <el-radio-button label="近30天"></el-radio-button>
          </el-radio-group>
          <!-- shortcut -->
          <el-date-picker v-model="datePickerA.value"
                          :picker-options="startDateDisabled"
                          type="daterange"
                          range-separator="-"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                          @change="loadData">
          </el-date-picker>
          <!-- picker -->
        </div>
        <!-- tools -->
        <div class="oc-box-content">
          <div class="oc-chart">
            <el-row :gutter="20">
              <el-col :span="8">
                <div class="oc-chart-item">
                  <dl class="oc-chart-title">
                    <dt>
                      <span>注册次数</span>
                      <el-tooltip content="按租户注册计量，用户使用手机号注册新租户的数量。"
                                  effect="light">
                        <i class="fa fa-question-circle"></i>
                      </el-tooltip>
                    </dt>
                    <dd>{{ sum(dataChartA) }}</dd>
                  </dl>
                  <!-- title -->
                  <bot-chart id="chartA"
                             class="oc-chart-main"
                             :options="setOptioin(dataDate, dataChartA)"
                             :height="'100px'"></bot-chart>
                  <!-- main -->
                </div>
              </el-col>
              <!-- chartA -->
              <el-col :span="8">
                <div class="oc-chart-item">
                  <dl class="oc-chart-title">
                    <dt>
                      <span>登录次数</span>
                      <el-tooltip content="按用户登录次数计量。"
                                  effect="light">
                        <i class="fa fa-question-circle"></i>
                      </el-tooltip>
                    </dt>
                    <dd>{{ sum(dataChartB) }}</dd>
                  </dl>
                  <!-- title -->
                  <bot-chart id="chartB"
                             class="oc-chart-main"
                             :options="setOptioin(dataDate, dataChartB)"
                             :height="'100px'"></bot-chart>
                  <!-- main -->
                </div>
              </el-col>
              <!-- chartB -->
              <el-col :span="8">
                <div class="oc-chart-item">
                  <dl class="oc-chart-title">
                    <dt>
                      <span>登录人数</span>
                      <el-tooltip content="按用户登录人数计量。"
                                  effect="light">
                        <i class="fa fa-question-circle"></i>
                      </el-tooltip>
                    </dt>
                    <dd>{{ sum(dataChartC) }}</dd>
                  </dl>
                  <!-- title -->
                  <bot-chart id="chartC"
                             class="oc-chart-main"
                             :options="setOptioin(dataDate, dataChartC)"
                             :height="'100px'"></bot-chart>
                  <!-- main -->
                </div>
              </el-col>
              <!-- chartC -->
              <el-col :span="8">
                <div class="oc-chart-item">
                  <dl class="oc-chart-title">
                    <dt>
                      <span>会话次数</span>
                      <el-tooltip content="按session计量，用户与机器人发交互开始，生成会话（session）的数量。"
                                  effect="light">
                        <i class="fa fa-question-circle"></i>
                      </el-tooltip>
                    </dt>
                    <dd>{{ sum(dataChartD) }}</dd>
                  </dl>
                  <!-- title -->
                  <bot-chart id="chartD"
                             class="oc-chart-main"
                             :options="setOptioin(dataDate, dataChartD)"
                             :height="'100px'"></bot-chart>
                  <!-- main -->
                </div>
              </el-col>
              <!-- chartD -->
              <el-col :span="8">
                <div class="oc-chart-item">
                  <dl class="oc-chart-title">
                    <dt>
                      <span>对话轮次</span>
                      <el-tooltip content="按消息计量，用户向机器人发送一条消息计为1个对话轮次。"
                                  effect="light">
                        <i class="fa fa-question-circle"></i>
                      </el-tooltip>
                    </dt>
                    <dd>{{ sum(dataChartE) }}</dd>
                  </dl>
                  <!-- title -->
                  <bot-chart id="chartE"
                             class="oc-chart-main"
                             :options="setOptioin(dataDate, dataChartE)"
                             :height="'100px'"></bot-chart>
                  <!-- main -->
                </div>
              </el-col>
              <!-- chartE -->
              <el-col :span="8">
                <div class="oc-chart-item">
                  <dl class="oc-chart-title">
                    <dt>
                      <span>短信发送次数</span>
                      <el-tooltip content="用户填写手机号，系统发送验证码短信的次数。"
                                  effect="light">
                        <i class="fa fa-question-circle"></i>
                      </el-tooltip>
                    </dt>
                    <dd>{{ sum(dataChartF) }}</dd>
                  </dl>
                  <!-- title -->
                  <bot-chart id="chartF"
                             class="oc-chart-main"
                             :options="setOptioin(dataDate, dataChartF)"
                             :height="'100px'"></bot-chart>
                  <!-- main -->
                </div>
              </el-col>
              <!-- chartF -->
            </el-row>
          </div>
        </div>
        <!-- content -->
      </el-card>
    </div>
    <!-- box -->
    <div class="oc-box">
      <el-card shadow="aways">
        <div class="oc-box-title clear-both">
          <div class="name">对话明细</div>
        </div>
        <!-- title -->
        <div class="oc-box-tools clear-both">
          <!-- <el-radio-group v-model="datePickerB.shortcut" @change="loadRoundDetail">
            <el-radio-button label="今天"></el-radio-button>
            <el-radio-button label="昨天"></el-radio-button>
            <el-radio-button label="前天"></el-radio-button>
            <el-radio-button label="近7天"></el-radio-button>
            <el-radio-button label="近14天"></el-radio-button>
            <el-radio-button label="近30天"></el-radio-button>
          </el-radio-group> -->
          <!-- shortcut -->
          <!-- <el-date-picker v-model="datePickerB.value"
                          @change="loadRoundDetail"
                          :picker-options="startDateDisabled"
                          type="daterange"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker> -->
          <!-- picker -->
        </div>
        <!-- tools -->
        <div class="oc-talbe">
          <el-table :data="tableData"
                    style="width: 100%">
            <el-table-column prop="date"
                             label="日期">
            </el-table-column>
            <el-table-column prop="te_count"
                             label="注册次数">
            </el-table-column>
            <el-table-column prop="login_times_count"
                             label="登录次数">
            </el-table-column>
            <el-table-column prop="login_account_count"
                             label="登录人数">
            </el-table-column>
            <el-table-column prop="session_count"
                             label="会话次数">
            </el-table-column>
            <el-table-column prop="history_count"
                             label="对话轮次">
            </el-table-column>
            <el-table-column prop="vcodelog_count"
                             label="短信发送次数">
            </el-table-column>
          </el-table>
        </div>
        <!-- table -->
      </el-card>
    </div>
    <!-- box -->
  </div>
</template>

<script>
// import echarts from 'echarts'
import * as echarts from 'echarts'
import BotChart from '@/views/components/bot_chart'
import moment from 'moment'

export default {
  name: 'OcStatistic',
  components: {
    BotChart
  },
  data () {
    return {
      pickerMinDate: '',
      dateRange: [],
      startDateDisabled: {
        onPick: ({ maxDate, minDate }) => {
          this.pickerMinDate = minDate.getTime()
          if (maxDate) {
            this.pickerMinDate = ''
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate !== '') {
            const day30 = (30 - 1) * 24 * 3600 * 1000
            let maxTime = this.pickerMinDate + day30
            let minTime = this.pickerMinDate - day30
            if (maxTime > new Date()) {
              maxTime = new Date()
            }
            return time.getTime() > maxTime || time.getTime() < minTime
          }
          return time.getTime() > Date.now()
        }
      },
      dateNow: moment(new Date()).format('YYYY年MM月DD日'),
      robotName: '机器人名字',
      creationTimes: '0',
      servicedNum: '0',
      datePickerA: {
        shortcut: '今天',
        value: ''
      },
      datePickerB: {
        shortcut: '今天',
        value: ''
      },
      tableData: [],
      dataChartA: [0, 0, 0, 0, 0, 0, 0],
      dataChartB: [0, 0, 0, 0, 0, 0, 0],
      dataChartC: [0, 0, 0, 0, 0, 0, 0],
      dataChartD: [0, 0, 0, 0, 0, 0, 0],
      dataChartE: [0, 0, 0, 0, 0, 0, 0],
      dataChartF: [0, 0, 0, 0, 0, 0, 0],
      dataDate: ['2019-02-01', '2019-08-22', '2019-12-02', '2020-01-01', '2020-02-01', '2020-03-01', '2020-04-01']
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    loadData () {
      let start_time = ''
      let end_time = moment().subtract(1, 'days').format('YYYY-MM-DD')
      switch (this.datePickerA.shortcut) {
        case '今天':
          start_time = moment().subtract(0, 'days').format('YYYY-MM-DD')
          end_time = moment().add(1, 'days').format('YYYY-MM-DD')
          break
        case '昨天':
          start_time = moment().subtract(1, 'days').format('YYYY-MM-DD')
          break
        case '前天':
          start_time = moment().subtract(2, 'days').format('YYYY-MM-DD')
          end_time = moment().subtract(2, 'days').format('YYYY-MM-DD')
          break
        case '近7天':
          start_time = moment().subtract(7, 'days').format('YYYY-MM-DD')
          break
        case '近14天':
          start_time = moment().subtract(14, 'days').format('YYYY-MM-DD')
          break
        case '近30天':
          start_time = moment().subtract(30, 'days').format('YYYY-MM-DD')
          break
      }
      if (this.datePickerA.value && this.datePickerA.value.length === 2) {
        start_time = moment(this.datePickerA.value[0]).format('YYYY-MM-DD')
        end_time = moment(this.datePickerA.value[1]).format('YYYY-MM-DD')
      }
      this.loadDetail()
      this.$http.get(`/api/Statistic/QueryStatisticByTime?start_time=${start_time}&end_time=${end_time}`)
        .then(res => {
          if (res && res.code === 0) {
            this.dataChartA = res.result.te_count
            this.dataChartB = res.result.login_times_count
            this.dataChartC = res.result.login_account_count
            this.dataChartD = res.result.session_count
            this.dataChartE = res.result.history_count
            this.dataChartF = res.result.vcodelog_count
            this.dataDate = res.result.section
          } else {
            this.$message(res ? res.msg : '请求异常')
          }
        })
    },
    loadDetail () {
      let start_time = ''
      let end_time = moment().subtract(1, 'days').format('YYYY-MM-DD')
      switch (this.datePickerA.shortcut) {
        case '今天':
          start_time = moment().subtract(0, 'days').format('YYYY-MM-DD')
          end_time = moment().add(1, 'days').format('YYYY-MM-DD')
          break
        case '昨天':
          start_time = moment().subtract(1, 'days').format('YYYY-MM-DD')
          break
        case '前天':
          start_time = moment().subtract(2, 'days').format('YYYY-MM-DD')
          end_time = moment().subtract(2, 'days').format('YYYY-MM-DD')
          break
        case '近7天':
          start_time = moment().subtract(7, 'days').format('YYYY-MM-DD')
          break
        case '近14天':
          start_time = moment().subtract(14, 'days').format('YYYY-MM-DD')
          break
        case '近30天':
          start_time = moment().subtract(30, 'days').format('YYYY-MM-DD')
          break
      }
      if (this.datePickerA.value && this.datePickerA.value.length === 2) {
        start_time = moment(this.datePickerA.value[0]).format('YYYY-MM-DD')
        end_time = moment(this.datePickerA.value[1]).format('YYYY-MM-DD')
      }
      this.$http.get(`/api/Statistic/QueryStatisticDetailByTime?bot_id=${this.$route.query.id}&start_time=${start_time}&end_time=${end_time}`)
        .then(res => {
          if (res && res.code === 0) {
            this.tableData = res.result.reverse()
            this.tableData.forEach(n => {
              n.history_count = n.history_count > 0 ? n.history_count : '-'
              n.login_account_count = n.login_account_count > 0 ? n.login_account_count : '-'
              n.login_times_count = n.login_times_count > 0 ? n.login_times_count : '-'
              n.session_count = n.session_count > 0 ? n.session_count : '-'
              n.te_count = n.te_count > 0 ? n.te_count : '-'
              n.vcodelog_count = n.vcodelog_count > 0 ? n.vcodelog_count : '-'
            })
          } else {
            this.$message(res ? res.msg : '请求异常')
          }
        })
    },

    sum (arr) {
      let sum = 0
      if (arr && arr.length > 0) {
        for (var i = 0; i < arr.length; i++) {
          sum += arr[i]
        }
      }
      return sum
    },
    setOptioin (x, y) {
      let xData = x || []
      let yData = y || []
      let option = {
        tooltip: {
          trigger: 'axis',
          // position: function (pt) {
          //   return [pt[0], '10%']
          // }
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '0',
          top: '0'
        },
        xAxis: {
          type: 'category',
          show: false,
          boundaryGap: false,
          data: xData
        },
        yAxis: {
          type: 'value',
          show: false,
          splitLine: {
            show: false
          }
        },
        series: [{
          data: yData,
          type: 'line',
          smooth: true,
          symbol: 'none',
          sampling: 'average',
          itemStyle: {
            color: '#466cb4'
          },
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(190, 205, 236,1)'
            }, {
              offset: 1,
              color: 'rgba(255, 255, 255,0)'
            }])
          }
        }]
      }
      return option
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
