<template>
  <div id="app" class="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  computed: {

  },
  mounted () {

},
  methods: {

  }
}
</script>

<style lang="scss" scoped>
</style>
