'use strict'

import axios from 'axios'
import router from '@/router'
import { Message } from 'element-ui'
axios.interceptors.request.use(function (config) {
    let requestUrl = config.url
    if (requestUrl.indexOf('nocache') < 0) {
        if (requestUrl.indexOf('?') < 0) {
            requestUrl += '?'
        } else {
            requestUrl += '&'
        }
        requestUrl += 'nocache=' + new Date().getTime()
    }
    config.url = requestUrl
    return config
}, function (error) {
    return Promise.reject(error)
})

axios.interceptors.response.use(function (response) {
    return response.data
}, function (error) {
    if (error && error.response) {
        let msg = '[' + error.response.status + ']错误'
        switch (error.response.status) {
            case 401:
                // window.location.href = `/api/auth/Office365?redirect_uri=` + encodeURIComponent(`${window.location.origin}`)
                // window.location.href = `https://zero.v2.aladdingo.com/api/auth/Office365?redirect_uri=` + encodeURIComponent(`${window.location.origin}/api/auth/callback?url=${window.location.origin}&jwt=`)
                window.location.href = '/#/login'
                break
            case 403:
                router.push('/403')
                break
            default:
                msg = '其它错误'
                if (error.response && error.response.data && error.response.data.Message) {
                    msg = msg + '：' + error.response.data.Message
                } else {
                    msg += '：其它错误'
                }
                Message({
                    showClose: true,
                    message: msg,
                    type: 'warning'
                })
        }
    }
    return Promise.reject(error)
})

export default {
    install(Vue) {
        Vue.prototype.$http = axios
        Vue.http = axios
    },
    $http: axios
}

export const $http = axios
