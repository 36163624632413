import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/shared/layout'
import E403 from '@/shared/403'
import E404 from '@/shared/404'

import OcStatistic from '@/views/statistic/index'
import OcHeartBeat from '@/views/monitor/heartbeat'
import OcException from '@/views/monitor/exception'
import Information from '@/views/customer/information'
import localization from '@/views/customer/localization'
import customerManager from '@/views/customer/manager'
import presetKnowledge from '@/views/preset/knowledge'
import Notice from '@/views/message/notice'
import Public from '@/views/message/public'
import Login from '@/views/login/index'

/* 解决ElementUI路由菜单点击相同路径报错 */
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/login',
      component: Login
    },
    {
      path: '/',
      component: Layout,
      redirect: '/monitor/heartbeat',
      children: [{
        path: '/statistic',
        name: 'OcStatistic',
        component: OcStatistic
      },
      {
        path: '/monitor/heartbeat',
        name: 'OcHeartBeat',
        component: OcHeartBeat
      },
      {
        path: '/monitor/exception',
        name: 'OcException',
        component: OcException
      },
      {
        path: '/customer/information',
        name: 'Information',
        component: Information
      },
      {
        path: '/customer/localization',
        name: 'localization',
        component: localization
      },
      {
        path: '/customer/manager',
        name: 'customerManager',
        component: customerManager
      },
      {
        path: '/preset/knowledge',
        name: 'presetKnowledge',
        component: presetKnowledge
      },
      {
        path: '/message/notice',
        name: 'notice',
        component: Notice
      },
      {
        path: '/message/public',
        name: 'public',
        component: Public
      },
      {
        path: '/403',
        name: '403',
        component: E403
      },
      {
        path: '/404',
        name: '404',
        component: E404
      }

      ]
    },
    {
      path: '*',
      name: 'any',
      redirect: '/404'
    }
  ]
})
