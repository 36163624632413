export default [{
  name: '监控',
  iconFont: 'fa fa-stethoscope',
  iconImg: '',
  isOpen: false,
  children: [{
    name: '服务监控',
    url: '/monitor/heartbeat'
  },
  {
    name: '异常日志',
    url: '/monitor/exception'
  }
  ]
},
{
  name: '统计',
  iconFont: 'fa fa-chart-area',
  iconImg: '',
  isOpen: false,
  url: '/statistic',
  children: []
},
{
  name: '客户管理',
  iconFont: 'fa fa-user',
  url: '/customer/information',
  isOpen: false,
  children: [{
    name: 'sass版本',
    url: '/customer/information'
  },
  {
    name: '本地版本',
    url: '/customer/localization'
  }]
},
{
  name: '预置管理',
  iconFont: 'fa fa-database',
  isOpen: false,
  children: [{
    name: '预置知识库',
    url: '/preset/knowledge'
  }]
},
{
  name: '消息管理',
  iconUnicode: '&#xe64c;',
  isOpen: false,
  children: [{
    name: '通知模板列表',
    url: '/message/notice'
  },
  {
    name: '系统公告列表',
    url: '/message/public'
  }
  ]
}
]
