<template>
  <div class="oc-msg-notice">
    <div class="of-box">

      <!-- 操作栏 -->
      <div class="of-container is-vertical">
        <div class="of-aside">
          <div class="of-filter clearfix">
            <div class="filter-left">
              <el-button size="small"
                         type="primary"
                         @click="showNotice = true">新建通知模板</el-button>
            </div>
          </div>

          <div class="of-filter clearfix">
            <div class="filter-left">
              <el-select v-model="queryStatus"
                         size="small"
                         style="width:100px">
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </div>

            <div class="filter-left" style="margin-left:10px">
              <el-select v-model="queryType"
                         size="small"
                         style="width:150px">
                <el-option v-for="item in noticeSelectType"
                           :key="item"
                           :label="item"
                           :value="item">
                </el-option>         
              </el-select>
            </div>

            <div class="filter-left" style="margin-left:10px">
              <el-date-picker
                v-model="queryTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="daterange"
                align="right"
                size="small"
                unlink-panels
                range-separator="——"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
              </el-date-picker>
            </div>

            <div class="filter-left" style="margin-left:10px">
              <div class="filter-group filter-item limit search-box">
                <el-input v-model="pageQueryParams.FastSearchKeyWord"
                          placeholder="搜索关键字"
                          prefix-icon="el-icon-search"
                          :clearable="true"
                          size="small"
                          @input="loadList()"></el-input>
              </div>
            </div>
          </div>
          
        </div>

        <!-- 数据表格 -->
        <div ref="tableBox"
             class="of-main">
          <div class="of-table">
            <el-table v-loading="loading"
                      :data="dataList">
              <el-table-column prop="title"
                               label="通知标题"></el-table-column>
              <el-table-column prop="type"
                               label="通知类型"></el-table-column>
              <el-table-column prop="create_person"
                               label="创建账户"></el-table-column>
              <el-table-column prop="created_time"
                               label="创建时间"></el-table-column>
              <el-table-column prop="status"
                               label="启用状态">
                <template slot-scope="scope">
                  <el-switch
                    v-model="scope.row.active_flag"
                    active-color="#466cb4"
                    inactive-color="#DCDFE6"
                    @change="updateStatus(scope.row)">
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column prop="operate"
                               label="操作">
                <template slot-scope="scope">
                  <el-button type="text"
                             @click="edit(scope.row)">编辑</el-button>
                  <el-button type="text"
                             @click="del(scope.row.Id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <!-- 分页组件 -->
        <div class="of-aside">
          <of-pagination :pageIndex="pageQueryParams.pageIndex"
                         :pageSize="pageQueryParams.pageSize"
                         :total="total"
                         @change="pageChange"></of-pagination>
        </div>

      </div>


      <!-- 添加通知模块 弹窗 -->
      <el-dialog title="通知模板" 
                 class="addDialog"
                 :visible.sync="showNotice"
                 append-to-body
                 :close-on-click-modal="false">
        <el-form ref="form"
                 :model="formData"
                 :rules="rules"
                 size="small">
          <el-form-item label="通知类型"
                        prop="type">
            <el-select v-model="formData.type"
                       placeholder="请选择通知类型"
                       size="small"
                       class="select"
                       style="width:200px">
              <el-option v-for="(item,index) in noticeType"
                         :key="index"
                         :label="item"
                         :value="item"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="通知标题"
                        prop="title">
            <el-input v-model="formData.title"
                      placeholder="请输入通知标题"></el-input>
          </el-form-item>

          <el-form-item label="通知详情"
                        prop="content">
            <el-input v-model="formData.content" 
                      type="textarea"
                      :rows="7"
                      placeholder="请输入通知详情"></el-input>
          </el-form-item>

          <el-form-item style="text-align:center">
            <el-button @click="showNotice = false">取消</el-button>
            <el-button type="primary" :disabled="disableSubmitBtn" @click="onSubmit">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>


      <!-- 编辑页面 -->
      <el-dialog title="通知模板" 
                 class="editDialog"
                 :visible.sync="showEditPage"
                 append-to-body
                 :close-on-click-modal="false">
        <el-form ref="editForm"
                 :model="showData"
                 :rules="rules"
                 size="small">
          <el-form-item label="通知类型"
                        prop="type">
            <el-select v-model="showData.type"
                       placeholder="请选择通知类型"
                       size="small"
                       class="select"
                       style="width:200px">
              <el-option v-for="(item,index) in noticeType"
                         :key="index"
                         :label="item"
                         :value="item"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="通知标题"
                        prop="title">
            <el-input v-model="showData.title"
                      placeholder="请输入通知标题"></el-input>
          </el-form-item>

          <el-form-item label="通知详情"
                        prop="content">
            <el-input v-model="showData.content" 
                      type="textarea"
                      :rows="7"
                      placeholder="请输入通知详情"></el-input>
          </el-form-item>

          <el-form-item style="text-align:center">
            <el-button @click="showEditPage = false">取消</el-button>
            <el-button type="primary" :disabled="disableSubmitBtn" @click="commitUpdate">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 查看详情 弹窗 -->
      <el-dialog title="通知详情" 
                 :visible.sync="showDetail"
                 :close-on-click-modal="false"
                 append-to-body>
        <el-divider></el-divider>
        <el-row>
          <el-col style="text-align:center;margin-bottom:15px"><div class="grid-content" style="font-size:large;font-weight:1000">{{ showData.title }}</div></el-col>
          <el-col style="text-align:center;margin-bottom:25px"><div class="grid-content" style="font-size:small">{{ showData.created_time }}</div></el-col>
          <el-col :span="20" :offset="2">
            <div class="grid-content" 
                 style="width:100%;word-wrap:break-word;text-indent"
                 v-html="showData.content"></div></el-col>
        </el-row>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import OfPagination from "@/components/pagination";

export default {
  name: 'Notice',
  components: {
    OfPagination
  },
  data() {
    return {
      dataList: [], // 列表数据
      loading: false,
      showNotice: false, // 显示创建窗口
      showDetail: false, // 显示消息详情
      showEditPage: false, // 显示编辑页面
      disableSubmitBtn: false, // 禁用提交按钮
      queryTime: "", // 数据过滤：时间段
      queryStatus: "all", // 数据过滤：启用状态
      queryType: "全部",
      formData: {
        title: "",
        created_time: "",
        content: "",
        type: "",
        create_person: "",
      },
      showData: {
        Id: "",
        Timestamp: "",
        active_flag: "",
        content: "",
        create_person: "",
        created_time: "",
        title: "",
        type: "",
        updated_time: ""
      },
      pageQueryParams: { // 查询参数
        PageIndex: 1,
        PageSize: 15,
        FastSearchKeyWord: "",
        SortField: "created_time",
        SortOrder: "desc",
        StartTime: "",
        EndTime: "",
        Status: "all",
        MsgType: "全部",
      },
      total: 0, // 数据总量
      noticeType: ["试用期剩余15天", "试用期剩余1天", 
                   "机器人已过期", "有效期剩余30天", "有效期剩余15天", "有效期剩余5天", "有效期剩余1天", 
                   "磁盘空间不足500M", "磁盘空间不足100M", "磁盘空间不足10M", "磁盘空间已满"
      ],
      noticeSelectType: [
          "全部",
          "试用期剩余15天", "试用期剩余1天", 
          "机器人已过期", "有效期剩余30天", "有效期剩余15天", "有效期剩余5天", "有效期剩余1天", 
          "磁盘空间不足500M", "磁盘空间不足100M", "磁盘空间不足10M", "磁盘空间已满"
      ],
      statusOptions: [
        {
          value: 'all',
          label: '全部'
        },
        {
          value: 'enabled',
          label: '启用'
        },
        {
          value: 'disabled',
          label: '禁用'
        }
      ], // 消息启用状态选项
      rules: {
        type: [
          { required: true, message: "请选择通知类型", trigger: "change" }
        ],
        title: [
          { required: true, message: "请填写通知标题", trigger: "blur" },
          { max: 255, message: "最大长度不超过255", trigger: "blur" }
        ],
        content: [
          { required: true, message: "请填写通知内容", trigger: "blur" }
        ]

      },
      pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },

    }
  },
  watch: {
    queryTime(val) {
      if (val) {
        this.pageQueryParams.StartTime = val[0];
        this.pageQueryParams.EndTime = val[1];
      }
      this.loadList();
    },
    queryStatus(val) {
      this.pageQueryParams.Status = val;
      this.loadList();
    },
    queryType(val) {
        this.pageQueryParams.MsgType = val;
        this.loadList();
    }
  },
  mounted () {
    this.loadList();
  },
  methods: {
    loadList() {
      this.loading = true;
      this.$http.post('/api/message/queryMessageNotice', this.pageQueryParams).then(res => {
        this.loading = false;
        if (res && res.Code == 0) {
          this.dataList = res.Data.rows
          this.total = res.Data.total
        } else {
          this.$message.error(res && res.Message ? res.Message : "获取数据出错！")
        }
      })
    },
    pageChange (index, size) {
      this.pageQueryParams.pageIndex = index;
      this.pageQueryParams.pageSize = size;
      this.loadList()
    },
    updateStatus(data) {
      // 启用时，需检查是否已经有同类型已启用的通知
      if (data.active_flag) {
        var promise = new Promise((resolve, reject) => {
          this.loading = true;
          this.$http.get(`/api/message/checkNoticeExist?type=${data.type}`).then(res => {
            if (res && res.Code == 0) {
              resolve();
            } else {
              this.$confirm('检查有同类型通知已启用，操作将自动禁用另一通知, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                reject(res.Data);
              }).catch(() => {
                this.loading = false;
                this.$message({
                  type: 'info',
                  message: '已取消启用'
                })
              })
            }
          });
        })

        promise.then(() => {
          this.disableOrEnableNotice(data);
        }).catch((Id) => {
          this.$http.get('/api/message/disableNotice?Id=' + Id).then(res => {
            if (res && res.Code == 0) {
              this.disableOrEnableNotice(data);
            } else {
              this.$message.error('启用失败');
            }
          })
        })
        
      } else {
        this.disableOrEnableNotice(data);
      }
      
      
    },
    disableOrEnableNotice(data) {
      this.loading = true;
      this.$http.get(`/api/message/updateNoticeStatus?id=${data.Id}`).then((res) => {
        this.loading = false;
        if (res && res.Code == 0) {
          var msg = data.active_flag ? "启用通知成功" : "禁用通知成功";
          this.$message.success(msg);
          this.loadList();
        } else {
          this.$message.error(res && res.Message ? res.Message : "获取数据出错！")
        }
      });
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;

          var promise = new Promise((resolve, reject) => {
            this.$http.get('/api/message/checkNoticeExist?type=' + this.formData.type).then(res => {
              if (res && res.Code == 0) {
                resolve();
              } else {
                this.loading = false;
                this.$confirm('添加同类型通知，将自动禁用另一通知, 是否继续?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.disableSubmitBtn = true;
                  reject(res.Data);
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消创建'
                  });      
                })
              }
            });
          })

          promise.then(() => {
            this.createNotice();
          }).catch((Id) => {
            this.loading = true;
            // 先禁用，再创建
            this.$http.get('/api/message/disableNotice?Id=' + Id).then(res => {
              if (res && res.Code == 0) {
                // 创建通知
                this.createNotice();
              } else {
                this.disableSubmitBtn = false;
                this.loading = false;
                this.$message.error("创建失败");
              }
            })
          })
        }
      })
    },
    commitUpdate() {
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          var promise = new Promise((resolve, reject) => {
            var param = "type=" + this.showData.type + "&id=" + this.showData.Id;
            this.$http.get('/api/message/checkNoticeExist?' + param).then(res => {
              if (res && res.Code == 0) {
                resolve();
              } else {
                this.$confirm('检测已有同类型通知，将自动禁用另一通知, 是否继续?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  // 禁用提交按钮
                  this.disableSubmitBtn = true;
                  reject(res.Data);
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消更新'
                  });
                })
              }
            });
          })

          promise.then(() => {
            this.updateNotice();
          }).catch((Id) => {
            // 先禁用，再更新
            this.$http.get('/api/message/disableNotice?Id=' + Id).then(res => {
              if (res && res.Code == 0) {
                // 更新通知
                this.updateNotice();
              } else {
                this.disableSubmitBtn = false;
                this.loading = false;
                this.$message.error("更新失败");
              }
            })
          })

          
        }
      })
    },
    updateNotice() {
      this.showData.content = this.showData.content.replace(/\n/gi, "<br/>");
      this.$http.post('/api/message/updateNotice', this.showData).then(res => {
        this.loading = false;
        this.showEditPage = false;
        this.disableSubmitBtn = false;
        if (res && res.Code == 0) {
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.Message);
        }
        this.loadList();
      })
    },
    createNotice() {
      this.formData.content = this.formData.content.replace(/\n/gi, "<br/>");
      // 直接创建
      this.$http.post('/api/message/createMessageNotice', this.formData).then((res) => {
        this.loading = false;
        this.disableSubmitBtn = false;
        if (res && res.Code == 0) {
          this.$message.success("创建成功");
        } else {
          this.$message.error(res && res.Message ? res.Message : "创建出错！");
        }
        this.showNotice = false;
        this.formData = {};
        this.loadList();
      })
    },
    edit(data) {
      this.showData.Id = data.Id;
      this.showData.Timestamp = data.Timestamp;
      this.showData.active_flag = data.active_flag;
      this.showData.content = data.content;
      this.showData.content = this.showData.content.replace(/<br\/>/g,'\n');
      this.showData.create_person = data.created_person;
      this.showData.created_time = data.created_time;
      this.showData.title = data.title;
      this.showData.type = data.type;
      this.showData.updated_time = data.updated_time;

      this.showEditPage = true;
    },
    del(id) {
      
      this.$confirm("删除消息", "系统提示",{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true;
        this.$http.get(`/api/message/deleteNotice?id=${id}`).then(res => {
          this.loading = false;
          if (res && res.Code == 0) {
            this.$message.success("删除成功");
          } else {
            this.$message.error(res && res.Message ? res.Message : "删除出错!");
          }
          this.loadList();
        })
      }).catch(() => {
        this.$message.info("取消删除操作");
      })
      
    }
  },
  
}
</script>

<style lang="scss" scoped>
.select{
display: inline-block;
}
</style>
