<template>
  <div class="of-box">
    <div class="of-container is-vertical">
      <!-- <div class="of-aside">
        <div class="of-filter clearfix">
          <div class="filter-left">
            <el-button size="small" type="primary" @click="add">新增</el-button>
          </div>
          <div class="filter-right">
            <div class="filter-group filter-item limit search-box">
              <el-input v-model="pageQueryParams.FastSearchKeyWord" placeholder="搜索关键字" prefix-icon="el-icon-search" :clearable="true" size="small" @input="onSearch"></el-input>
            </div>
          </div>
        </div>
      </div> -->
      <div ref="tableBox" class="of-main">
        <div class="of-table">
          <el-table v-loading="loading" :data="list">
            <el-table-column prop="sn" label="订阅编号"></el-table-column>
            <el-table-column prop="bot_name" label="机器人名称"></el-table-column>
            <el-table-column prop="service_params" label="订阅服务">
              <template slot-scope="scope">
                <div v-for="item in JSON.parse(scope.row.service_params)" :key="item.name">
                  {{item.name}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="expired_time" label="到期时间"></el-table-column>
            <el-table-column prop="create_time" label="订阅时间"></el-table-column>
            <!-- <el-table-column prop="created_time" label="操作人" width="150"></el-table-column> -->
            <!-- <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <el-button type="text" @click="edit(scope.row)">重命名</el-button>
                <el-button type="text" @click="del(scope.row.Id)">删除</el-button>
              </template>
            </el-table-column> -->
          </el-table>
        </div>
      </div>
      <!-- <div class="of-aside">
        <of-pagination :pageIndex="pageQueryParams.pageIndex" :pageSize="pageQueryParams.pageSize" :total="total" @change="pageChange"></of-pagination>
      </div> -->
    </div>
  </div>
</template>
<script>
// import OfPagination from "@/components/pagination";
export default {
  // components: { OfPagination },
  data() {
    return {
      loading: false,
      list: [],
      pageQueryParams: {
        PageIndex: 1,
        PageSize: 10000,
        FastSearchKeyWord: "",
        SortField: "create_time",
        SortOrder: "desc",
      },
      total: 0

    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    // 搜索
    onSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.pageQueryParams.PageIndex = 1;
        this.loadList();
      }, 800);
    },
    pageChange(index, size) {
      this.pageQueryParams.pageIndex = index;
      this.pageQueryParams.pageSize = size;
      //   let postData = {
      //     PageIndex: this.pageIndex,
      //     PageSize: this.pageSize,
      //   };
      this.loadList()
    },
    loadList() {
      this.loading = true
      this.pageQueryParams.sid = this.$route.query.Id
      this.$http.post('/api/subscribe/query', this.pageQueryParams).then(res => {
        this.loading = false
        if (res && res.Code == 0) {
          this.list = res.Data.rows
          this.total = res.Data.total
        } else {
          this.$message.error(res && res.Message ? res.Message : "获取数据出错！")
        }
      })
    }
  }

}
</script>