<template>
  <div class="of-box">
    <div class="of-container is-vertical">
      <div class="of-aside">
        <div class="of-filter clearfix">
          <div class="filter-right">
            <div class="filter-group filter-item limit search-box">
              <el-input v-model="pageQueryParams.FastSearchKeyWord" placeholder="搜索关键字" prefix-icon="el-icon-search" :clearable="true" size="small" @input="onSearch"></el-input>
            </div>
          </div>
        </div>
      </div>
      <div ref="tableBox" class="of-main">
        <div class="of-table">
          <el-table v-loading="loading" :data="listData">
            <el-table-column prop="Name" label="公司名"> </el-table-column>
            <el-table-column prop="Type" label="客户类型"> </el-table-column>
            <el-table-column prop="Manage" label="管理员"> </el-table-column>
            <el-table-column prop="Phone" label="手机"> </el-table-column>
            <el-table-column prop="Email" label="邮箱"> </el-table-column>
            <el-table-column prop="MaxBotNum" label="机器人总数"> </el-table-column>
            <!-- <el-table-column prop="BootNum" label="已建机器人数"></el-table-column>
            <el-table-column label="可建机器人数">
              <template slot-scope="scope">
                {{scope.row.MaxBotNum - scope.BootNum}}
              </template>
            </el-table-column> -->
            <el-table-column prop="CreateTime" label="创建时间">
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
              <template slot-scope="scope">
                <el-button class="table-ctrlbtn" type="text" size="small" icon="fa fa-edit" @click="goto(scope.row)">管理</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="of-aside">
        <of-pagination :pageIndex="pageQueryParams.pageIndex" :pageSize="pageQueryParams.pageSize" :total="pageQueryParams.totalCount" @change="pageChange"></of-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
import OfPagination from "@/components/pagination";
export default {
  components: { OfPagination },
  data() {
    return {
      listData: [
      ],
      pageQueryParams: {
        PageIndex: 1,
        PageSize: 15,
        FastSearchKeyWord: "",
        FastSearchFields: 'custom_mobile',
        SortField: "timestamp",
        SortOrder: "desc",
      },
      total: 0,
      loading: false,
    };
  },
  methods: {
    pageChange(index, size) {
      this.pageQueryParams.pageIndex = index;
      this.pageQueryParams.pageSize = size;
      //   let postData = {
      //     PageIndex: this.pageIndex,
      //     PageSize: this.pageSize,
      //   };
      this.loadList()
    },
    // 搜索
    onSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.pageQueryParams.PageIndex = 1;
        this.loadList();
      }, 800);
    },
    loadList() {
      let params = Object.assign(this.pageQueryParams);
      this.$http.post("/api/tenantBot/query", params).then((res) => {
        if (res.Code == 0) {
          let data = res.Data;
          this.listData = data.Rows;
          this.pageQueryParams.totalCount = data.Total;
        }
      });
    },
    pageSizeChange(size) {
      this.pageQueryParams.PageSize = size;
      this.pageQueryParams.PageIndex = 1;
      this.loadList();
    },
    pageIndexChange(index) {
      this.pageQueryParams.PageIndex = index;
      this.loadList();
    },
    // getTime(item) {
    //   return moment(Number(item)).format("YYYY-MM-DD HH:mm:ss");
    // },
    goto(row) {
      console.log(row);
      this.$router.push('/customer/manager?Id=' + row.Id);
    },
    addNew() {
      this.addFormVisible = true
      this.formData = {
        bot_name: "",
        created_time: this.$date.format("yyyy-MM-dd", new Date()),
        expired_time: "",
        capacity: {
          checkbox: [],
          count: 5,
        },
      }
    },
    onSubmit() {
      this.$refs.form.submit().then(res => {
        console.log(res)
      })
    },
    onCancel() {
      this.addFormVisible = false
    }
  },
  mounted() {
    this.loadList();
  },
};
</script>

<style lang="scss" scoped>
.oc-container {
  margin-top: 1rem;
  .oc-title {
    margin-bottom: 30px;
    .oc-title-text {
      float: left;
    }
  }
  .opera-box {
    .opera-box-left {
      float: left;
    }
    .opera-box-right {
      float: right;
    }
  }
  .oc-content {
    margin-bottom: 20px;
    .oc-content-table {
      min-height: 350px;
    }
    .oc-content-table-page {
      text-align: right;
    }
  }
}
.oc-drawer {
  .oc-drawer-header {
    padding: 20px;
    .oc-drawer-title {
      font-size: 18px;
    }
  }
  .oc-drawer-content {
    background: #f5f7fa;
    position: absolute;
    bottom: 0;
    top: 65px;
    right: 0;
    left: 0;
    overflow: auto;
    ul li {
      margin: 20px;
    }
    .msg-left {
      .name {
        color: #444;
        max-width: 120px;
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        display: inline-block;
      }
      .content {
        max-width: 400px;
        line-height: 24px;
        padding: 6px 12px;
        background: #fff;
        border-radius: 0 18px 18px 18px;
        border: 1px solid #e0e0e0;
        word-break: break-word;
        display: inline-flex;
      }
      text-align: left;
    }
    .msg-right {
      .name {
        color: #444;
        max-width: 120px;
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        display: inline-block;
      }
      .content {
        max-width: 400px;
        line-height: 24px;
        padding: 6px 12px;
        background: #43c3c6;
        border-radius: 18px 0 18px 18px;
        color: #fff;
        text-align: left;
        word-break: break-word;
        display: inline-flex;
      }
      text-align: right;
    }
  }
}
</style>
