import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const appconfig = {
  TechClassificationListKey: 'TechClassificationList',
  TechListKey: 'TechList',
  TechDetailListKey: 'TechDetailList'
}

const state = {
  techClassificationList: [],
  techList: [],
  techDetailList: [],
  loading: false
}

export default new Vuex.Store({
  state,
  getters: {
    GetTechClassificationList () {
      let r = localStorage.getItem(appconfig.TechClassificationListKey)
      if (r) {
        state.techClassificationList = JSON.parse(r)
      }
      return state.techClassificationList
    },
    GetTechList () {
      let r = localStorage.getItem(appconfig.TechListKey)
      if (r) {
        state.techList = JSON.parse(r)
      }
      return state.techList
    },
    GetTechDetailList () {
      let r = localStorage.getItem(appconfig.TechDetailListKey)
      if (r) {
        state.techDetailList = JSON.parse(r)
      }
      return state.techDetailList
    },
    GetLoading () {
      return state.loading
    }
  },
  mutations: {
    UPDATE_TECH_CLASSIFICATION_LIST (state, data) {
      state.techClassificationList = data
      localStorage.setItem(appconfig.TechClassificationListKey, JSON.stringify(state.techClassificationList))
    },
    UPDATE_TECH_LIST (state, data) {
      state.techList = data
      localStorage.setItem(appconfig.TechListKey, JSON.stringify(state.techList))
    },
    UPDATE_TECH_DETAIL_LIST (state, data) {
      state.techDetailList = data
      localStorage.setItem(appconfig.TechDetailListKey, JSON.stringify(state.techDetailList))
    },
    UPDATE_LOADING (state, data) {
      state.loading = data
    }
  }
})
