<template>
  <div v-if="showMenu"
       class="of-container">
    <div class="of-menu-main">
      <ul>
        <li v-for="(item, index) in menuList"
            :key="index"
            :class="{'active': index === activePath[0], 'open': item.isOpen }">
          <el-tooltip effect="dark"
                      :content="item.name"
                      placement="right">
            <span @click="clickItem(menuList, index, 'main')">
              <i v-if="item.iconFont"
                 :class="item.iconFont"></i>
              <i v-else-if="item.iconImg">
                <img :src="item.iconImg" />
              </i>
              <i v-else-if="item.iconText">
                <font>{{ item.iconText }}</font>
              </i>
               <i v-else-if="item.iconUnicode" class="icon iconfont"  v-html="item.iconUnicode" >
              </i>
              <i v-else
                 class="fa fa-th-large"></i>
            </span>
          </el-tooltip>
          <div class="of-menu-sub">
            <div class="of-menu-title">{{ item.name }}</div>
            <ul>
              <li v-for="(subItem, subIndex) in item.children"
                  :key="subIndex"
                  :class="{
                    'active': subItem.crumbs[0] === activePath[0] && subIndex === activePath[1],
                    'open': subItem.isOpen
                  }">
                <span @click="clickItem(item.children, subIndex)">
                  <i v-if="hasChildren(subItem)"
                     class="fa fa-chevron-down"></i>
                  <a>{{ subItem.name }}</a>
                </span>
                <ul>
                  <li v-for="(childItem, childIndex) in subItem.children"
                      :key="childIndex"
                      :class="{ 'active': childItem.crumbs[0] === activePath[0] && childItem.crumbs[1]=== activePath[1] && childIndex === activePath[2] }"
                      @click="clickItem(subItem.children, childIndex)">
                    <a>{{ childItem.name }}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <!-- sub -->
        </li>
      </ul>
    </div>
    <!-- <div class="of-menu-robot">
      <el-tooltip effect="dark"
                  content="底部头像"
                  placement="right">
        <span>
          <img src="@/assets/images/base/header-default.jpg" />
        </span>
      </el-tooltip>
    </div> -->
    <!-- main -->
  </div>
</template>

<script>
import menuData from './menu.js'
export default {
  props: {
    showMenu: Boolean
  },
  data () {
    return {
      activePath: [],
      menuList: menuData
    }
  },
  watch: {
    $route (val) {
      this.foreachMenu(this.menuList, val.path, [])
      this.foreachMenuOpen(this.menuList, 0, this.activePath.length)
    }
  },
  created () {
    const path = this.$route.path
    this.foreachMenu(this.menuList, path, [])
    this.foreachMenuOpen(this.menuList, 0, this.activePath.length)
  },
  methods: {
    foreachMenu (arr, path, crumbs) {
      for (let i = 0; i < arr.length; i++) {
        arr[i].crumbs = JSON.parse(JSON.stringify(crumbs))
        arr[i].crumbs.push(i)
        if (arr[i].url === path) {
          this.activePath = arr[i].crumbs
        }
        if (arr[i].children) {
          this.foreachMenu(arr[i].children, path, arr[i].crumbs)
        }
      }
    },
    foreachMenuOpen (arr, level, total) {
      const index = this.activePath[level]
      const item = arr[index]
      if (item && level <= total && item.children && item.children.length > 0 &&
        !(level === 0 && this.activePath.length == 1 && item.url !== undefined && item.url !== '')) {
        item.isOpen = true
        this.foreachMenuOpen(item.children, level + 1, total)
      }
      if (level === 0) this.$emit('handleSub', item === undefined ? false : item.isOpen)
    },
    clickItem (data, index, type) {
      if (type === 'main') {
        data.forEach((dataItem, dataIndex) => {
          if (dataIndex !== index) {
            dataItem.isOpen = false
          }
        })
      }
      let item = data[index]
      let menu = this.menuList[this.activePath[0]]
      if (menu && item.name === menu.name && this.activePath.length > 1) {
        item.isOpen = !item.isOpen
      } else if (menu && item.name === menu.name && this.activePath.length == 1) {
        item.isOpen = false
      } else if (this.hasUrl(item) && this.hasChildren(item) && item.crumbs.length > 1) {
        this.goto(item.url)
        item.isOpen = !item.isOpen
      } else if (this.hasUrl(item)) {
        this.goto(item.url)
      } else {
        item.isOpen = !item.isOpen
      }
      if (type === 'main') this.$emit('handleSub', item.isOpen)
    },
    hasChildren (item) {
      let hasChildren = false
      if (item && item.children && item.children.length > 0) {
        hasChildren = true
      }
      return hasChildren
    },
    hasUrl (item) {
      return item.url && item.url.length > 0
    },
    goto (path) {
      if (path) {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: 'iconfont';
  src: url('../assets/fonts/iconfont.ttf') format('truetype');
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 28px !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
