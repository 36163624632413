<template>
  <div class="oc-form">
    <el-form ref="form"
             :model="form"
             :rules="rules"
             label-width="80px"
             size="small"
             label-position="top">
      <el-form-item label="机器人名称"
                    prop="bot_name">
        <el-input v-model="form.bot_name"
                  placeholder="请输入项目名称"></el-input>
      </el-form-item>
      <el-form-item label="创建时间"
                    prop="created_time">
        <el-date-picker v-model="form.created_time"
                        disabled
                        value-format="yyyy-MM-dd"
                        type="date"
                        placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="过期时间"
                    prop="expired_time">
        <el-date-picker v-model="form.expired_time"
                        value-format="yyyy-MM-dd"
                        type="date"
                        placeholder="选择日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="可用能力"
                    prop="capacity">
        <el-checkbox-group v-model="form.capacity.checkbox"
                           @change="change">
          <el-checkbox label="qna"
                       value="qna">FAQ问答</el-checkbox>
          <el-checkbox label="doc"
                       value="doc">文档对话</el-checkbox>
          <el-checkbox label="table"
                       value="table">表格对话</el-checkbox>
          <el-checkbox label="skill"
                       value="skill">多轮对话</el-checkbox>
          <el-checkbox v-show="false" label="seat"
                       value="seat">人工坐席 坐席数:{{ form.capacity.count }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="FAQ问答数量"
                    prop="faq_num">
        <el-input v-model="form.faq_num"
                  placeholder="请输入限制数量"
                  @input="validateNumber" />
      </el-form-item>
      <el-form-item label="备注说明"
                    prop="change_faq_num_reson">
        <span slot="label">
          备注说明
          <el-tooltip class="item"
                      effect="light"
                      content="记录修改FAQ问答数量的原因"
                      placement="right">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </span>
        <el-input v-model="form.change_faq_num_reason"
                  :rows="3"
                  :maxlength="1000"
                  type="textarea"
                  placeholder="请输入原因"
                  show-word-limit />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    formData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data () {
    return {
      pageData: [],
      pageHeight: 550,
      selectRows: [],
      form: {
        bot_name: "",
        created_time: this.$date.format("yyyy-MM-dd", new Date()),
        expired_time: "",
        capacity: {
          checkbox: [],
          count: 5,
        },
        faq_num: 1000,
        change_faq_num_reason: ''
      },
      rules: {},
    };
  },
  watch: {
    formData: {
      handler (val) {
        this.form = Object.assign(this.form, val);
        console.log("form", this.form);
      },
      deep: true,
    },
  },
  mounted () {
    this.form = Object.assign(this.form, this.formData);
    if (this.form.bot_ability != null && this.form.bot_ability != "") {
      this.form.capacity = JSON.parse(this.form.bot_ability);
    }
  },
  methods: {
    validateNumber (value) {
      if (/(^[1-9]\d*$)/.test(value))
        return
      var newValue = ''
      var arr = value.split('')
      console.log(arr, arr.length)
      arr.forEach(c => {
        if (/(^[0-9]\d*$)/.test(c))
          newValue += c
      })
      this.form.faq_num = Number(newValue)
    },
    change (val) {
      console.log(val);
    },
    submit (callback) {
      let _this = this;
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 封装过期时间为当天的23:59:59过期
          if (_this.form.expired_time != null && _this.form.expired_time != undefined && _this.formData.expired_time != "") {
            let time = "23:59:59";
            _this.form.expired_time = moment(`${_this.form.expired_time} ${time}`, 'YYYY-MM-DD HH:mm:ss').format();
          }
          let postData = _this.form;
          postData["bot_ability"] = JSON.stringify(this.form.capacity);
          console.log(postData);
          this.$http.post("/api/tenantBot/save", postData).then((res) => {
            if (res && res.Code === 0) {
              callback(true, "保存成功");
            } else {
              let err = res ? res.Message : "服务器请求超时，请稍后再试。";
              this.$message.error(err);
              callback(false, err);
            }
          });
        } else {
          callback(false, "表单验证不通过");
        }
      });
    },
    // 清空表单验证函数
    clearValidate () {
      this.$refs.form.resetFields();
    },
    // 重置表单
    resetFields (props) {
      this.$refs.form.resetFields(props);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
