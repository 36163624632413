<template>
  <el-drawer ref="drawer"
             class="of-drawer"
             :visible.sync="visible"
             append-to-body
             :with-header="false"
             :size="size"
             :direction="direction"
             :modal="modal"
             :wrapperClosable="wrapperClosable"
             :before-close="cancel"
             @close="cancel">
    <div class="of-box">
      <div class="of-container is-vertical">
        <div class="of-aside">
          <div class="of-box-header clearfix">
            <div class="header-text box-left">
              <dl>
                <dt class="clearfix">
                  <span>{{ title }}</span>
                </dt>
                <dd>{{ tip }}</dd>
              </dl>
            </div>
          </div>
        </div>
        <!-- aside -->
        <div class="of-main">
          <el-row>
            <el-col :span="24">
              <slot v-if="visible"
                    name="content"></slot>
            </el-col>
          </el-row>
        </div>
        <!-- main -->
        <div class="of-aside">
          <div class="of-filter clearfix">
            <div class="filter-right">
              <slot name="footer">
                <el-button size="small"
                           @click="cancel">{{ cancelName }}</el-button>
                <el-button type="primary"
                           size="small"
                           :loading="isSubmit"
                           @click="submit">{{ isSubmit ? '提交中 ...' : submitName }}</el-button>
              </slot>
            </div>
          </div>
        </div>
        <!-- footer -->
      </div>
    </div>
  </el-drawer>
</template>

<script>

export default {
  components: {
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    tip: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: '600px'
    },
    direction: {
      type: String,
      default: 'rtl'
    },
    isSubmit: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: true
    },
    wrapperClosable: {
      type: Boolean,
      default: false
    },
    cancelName: {
      type: String,
      default: '取 消'
    },
    submitName: {
      type: String,
      default: '确 定'
    }
  },
  data () {
    return {
    }
  },
  methods: {
    cancel () {
      this.$emit('onCancel')
    },
    submit () {
      this.$emit('onSubmit')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
