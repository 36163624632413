<template>
  <div class="page-404">
    <div class="page-content">
      404，页面未找到
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
.page-404 .page-content
{
  margin-top: 50px;
  width:100%;
  height:80px;
  line-height: 80px;
  color:#bbb;
  text-align: center;
  font-size: 40px;
}
</style>
