<template>
  <div class="oc-container-ms">
    <div class="oc-title oc-title-menu">
      <el-card shadow="aways">
        <div class="clear-both">
          <div class="oc-title-text">服务监控</div>
        </div>
      </el-card>
    </div>
    <!-- title -->
    <div class="oc-container-fix">
      <div class="oc-container">
        <div class="oc-content">
          <el-card shadow="aways">
            <div class="oc-content-table">
              <el-table v-loading="loading"
                        :data="listData">
                <el-table-column prop="server_name"
                                 label="服务名称">
                </el-table-column>
                <el-table-column prop="pod_name"
                                 label="实例名称">
                </el-table-column>
                <el-table-column prop="last_heartbeat"
                                 label="最后心跳时间">
                  <template slot-scope="scope">
                    <span v-html="getTimeHtml(scope.row)"></span>
                  </template>
                </el-table-column>
                <el-table-column prop="ex_last_time"
                                 label="最后异常时间">
                  <template slot-scope="scope">
                    <span v-html="getExTimeHtml(scope.row)"></span>
                  </template>
                </el-table-column>
                <el-table-column prop="ex_hour_count"
                                 label="最近1小时异常数">
                </el-table-column>
                <el-table-column prop="ex_day_count"
                                 label="最近1天异常数">
                </el-table-column>
                <el-table-column prop="alive"
                                 label="运行状态">
                  <template slot-scope="scope">
                    <span v-html="getStatus(scope.row)"></span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'OcHeartBeat',
  components: {},
  data () {
    return {
      listData: [],
      loading: false
    }
  },
  mounted () {
    this.loadList()
  },
  methods: {
    loadList () {
      this.loading = true
      this.$http.get('/api/monitor/ViewAll?hour=100')
        .then(res => {
          this.loading = false
          if (res && res.code === 0) {
            this.listData = res.result
          } else {
            this.$message.error(res ? res.msg : '请求异常')
          }
        })
    },
    getTimeHtml (item) {
      var date = Number(item.last_heartbeat)
      var dateStr = moment(date).format('YYYY-MM-DD HH:mm:ss')
      if (item.alive) return `<span style="color: #67C23A;">${dateStr}</span>`
      else return `<span style="color: #F56C6C;">${dateStr}</span>`
    },
    getExTimeHtml (item) {
      var date = Number(item.ex_last_time)
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    getStatus (item) {
      if (item.alive) return `<span style="color: #67C23A;">在线</span>`
      else return `<span style="color: #F56C6C;">离线</span>`
    }
  }
}
</script>

<style lang="scss" scoped>
.oc-container {
  padding: 20px;
  .oc-title {
    margin-bottom: 30px;
    .oc-title-text {
      float: left;
    }
  }
  .opera-box {
    .opera-box-left {
      float: left;
    }
    .opera-box-right {
      float: right;
    }
  }
  .oc-content {
    margin-bottom: 20px;
    .oc-content-table {
      min-height: 350px;
    }
    .oc-content-table-page {
      text-align: right;
    }
  }
}
.oc-drawer {
  .oc-drawer-header {
    padding: 20px;
    .oc-drawer-title {
      font-size: 18px;
    }
  }
  .oc-drawer-content {
    background: #f5f7fa;
    position: absolute;
    bottom: 0;
    top: 65px;
    right: 0;
    left: 0;
    overflow: auto;
    ul li {
      margin: 20px;
    }
    .msg-left {
      .name {
        color: #444;
        max-width: 120px;
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        display: inline-block;
      }
      .content {
        max-width: 400px;
        line-height: 24px;
        padding: 6px 12px;
        background: #fff;
        border-radius: 0 18px 18px 18px;
        border: 1px solid #e0e0e0;
        word-break: break-word;
        display: inline-flex;
      }
      text-align: left;
    }
    .msg-right {
      .name {
        color: #444;
        max-width: 120px;
        overflow: hidden;
        word-break: keep-all;
        text-overflow: ellipsis;
        display: inline-block;
      }
      .content {
        max-width: 400px;
        line-height: 24px;
        padding: 6px 12px;
        background: #43c3c6;
        border-radius: 18px 0 18px 18px;
        color: #fff;
        text-align: left;
        word-break: break-word;
        display: inline-flex;
      }
      text-align: right;
    }
  }
}
</style>
