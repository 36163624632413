<template>
  <div class="bot-chart">
    <div :id="id"
         ref="botChart"
         :style="{height:height,width:width}"></div>
  </div>
</template>
<script>
// import echarts from 'echarts'
import * as echarts from 'echarts'
export default {
  name: 'BotChart',
  props: {
    className: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      timer: null,
      chartOption: {},
      isLoading: false
    }
  },
  watch: {
    options () {
      this.setChart()
    }
  },
  mounted () {
    this.setChart()
    window.addEventListener('resize', () => {
      this.chart.resize()
    })
  },
  methods: {
    setChart () {
      var self = this
      this.chartOption = this.options
      this.chartOption.color = ['#ff7f50', '#87cefa', '#da70d6', '#32cd32', '#6495ed',
        '#ff69b4', '#ba55d3', '#cd5c5c', '#ffa500', '#40e0d0',
        '#1e90ff', '#ff6347', '#7b68ee', '#00fa9a', '#ffd700',
        '#6699FF', '#ff6666', '#3cb371', '#b8860b', '#30e0e0']
      this.chart = echarts.init(this.$refs.botChart, 'shine')
      this.chart.setOption(this.chartOption)
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.chart.resize()
      }, 300)
      if (this.options.id === 'skill' || this.options.id === 'kb') {
        this.chart.on('click', function (params) {
          self.$emit('onClick', params)
        })
      }
    }
  }
}
</script>
